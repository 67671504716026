import { Box } from "@mui/material";
import Layout from "../../Components/Layout";
import { Theme, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

const Dashboard = () => {
  const useStyles = makeStyles((theme: Theme) => ({
    navlinks: {
      marginLeft: theme.spacing(10),
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    logo: {
      flexGrow: 1,
      cursor: "pointer",
    },
    link: {
      textDecoration: "none",
      color: "white",
      fontSize: "16px",
      marginLeft: theme.spacing(10),
      "&:hover": {
        borderBottom: "1px solid white",
      },
    },
  }));

  const classes: Record<string, string> = useStyles();

  const { pathname } = useLocation();

  if (!pathname) return null;
  return (
    <Layout  showFilterSidebar={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Link to="/introduction" className={classes.link}>
          Home
        </Link>
      </Box>
    </Layout>
  );
};

export default Dashboard;
