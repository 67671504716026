import axios from "axios";

const nginxBaseUrl = "getBackendURL";
let baseURL: string = "";

axios
  .get(nginxBaseUrl)
  .then((response) => {
    baseURL =
      response.data.backendURL ||
      "https://nextdrought-api-wrd-dev.eu.mike-cloud-dev.com";
  })
  .catch((error) => {
    console.error("Failed to fetch base URL from Nginx:", error);
  });

const api = axios.create({
  baseURL,
  headers: {
    "Content-type": "application/json",
  },
});

export default api;
