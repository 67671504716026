import { useQuery } from "react-query";
import { getLegends } from "../services/legends";

interface UseLegends {
  layerId: string;
  aggregationLevel?:string;

}

const useLegends = ({ layerId, aggregationLevel }: UseLegends) => {
  const isEnabled = !!layerId;

  const legends = useQuery(
    ["legends", layerId],
    async () =>
      await getLegends({
        layerId,
        aggregationLevel
      }),
    {
      enabled: isEnabled,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  return legends;
};

export default useLegends;
