import { useEffect } from "react";
import { Box, Accordion, AccordionProps, styled } from "@mui/material";

import Layout from "../../Components/Layout";

import useQueryParameters from '../../hooks/useQueryParameters'

import DroughtMap from "../../Components/DroughtMap/DroughtMap";
import useArea from "../../hooks/useArea";
import { useNavigate } from "react-router";
import { layersData } from "../../helpers/src/Layersstore";
import { TARGET_COUNTRY } from "../../enums/targetCountry";
import { MAP_SHIFT_CONSTANT } from "../../enums/map";

const DroughtIndices = () => {
  const { queryParameters, setQueryParameters, createQueryParametersString } =
    useQueryParameters();

  useEffect(() => {
    const mergedLayers = layersData.map((layer) => ({
      ...layer,
      date: queryParameters.layers[0].date,
      year: queryParameters.layers[0].year
    }));

    setQueryParameters({
      layers: mergedLayers,
      latitude: TARGET_COUNTRY[queryParameters.country].latitude,
      longitude: TARGET_COUNTRY[queryParameters.country].longitude + ((layersData.length + 1) * (window.innerWidth/MAP_SHIFT_CONSTANT)),
      zoom: 4.5,
     })
  }, [])

  const { data, isLoading, error } = useArea(
    queryParameters.country ? queryParameters.country : ""
  );

  let optionss = [];
  if (data) {
    optionss = data.map((item: { name: any }) => item.name);
  }

  const navigate = useNavigate();
  const handleBack = () => {
    const pathname = "/dashboard/drought-risk";
    const queryParameters1 = createQueryParametersString({});

    navigate({
      pathname: `${pathname}`,
      search: queryParameters1,
    });
  };

  const handleNext = () => {
    const pathname = "/dashboard/financial-indices";
    const queryParameters1 = createQueryParametersString({});

    navigate({
      pathname: `${pathname}`,
      search: queryParameters1,
    });
  };

  return (
      <Layout
        next={handleNext}
        back={handleBack}
        nextTitle="Next"
        backTitle="Back"
        showFilterSidebar={true}
      >
        <Box
            sx={{
              display: "flex",
              height: "100%",
            }}
          >
        {
          queryParameters.layers.length === 0 ?
            <DroughtMap
              layerId=""
              width={600}
              height={600}
            /> :
            (
              queryParameters.layers.map(({ displayName, id }, index) => (
                <DroughtMap
                  width={600}
                  height={600}
                  key={index}
                  title={displayName}
                  layerId={id}
                  details={index === 0}
                  timeslider={index === 0}
                  timeseries={false}
                />
              ))
            )
          }
      </Box>
    </Layout>
  );
};

export default DroughtIndices;

const AccordionStyled = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: "300px",
  borderTop: "none",
  borderRight: "none",
  borderLeft: "none",
}));
