import { useLocation, useNavigate } from "react-router-dom";
import { Buffer } from "buffer";
import { LayerTime } from "../types/layers";
import { useCallback } from "react";
import { GetTimeseriesResponse } from "../types/timeseries";
import { layersData } from "../helpers/src/Layersstore";

import { GetFeatureInfoResponse } from "../types/featureInfo";
import { TARGET_COUNTRY } from "../enums/targetCountry";
import { MAP_SHIFT_CONSTANT } from "../enums/map";

export interface QueryLayer {
  id: string;
  timeseriesGroupId?: string;
  parentId?: string;
  displayName?: string;
  dataunit?: string;
  date?: Date;
  year?: string;
  dateMode?: "picker" | "slider";
  time?: LayerTime | null;
}

export interface TimeseriesSettings {
  resampled?: "year" | "month" | "week" | "day" | "none" | string;
  accumulated?: "all" | "year" | "month" | "week" | "day" | "none" | string;
  compare?: "yearly" | "seasonal" | "none" | string;
}

export interface TimeseriesQuery {
  queryId?: string;
  tabId?: string;
  settings?: TimeseriesSettings;
  monthFrom?: number;
  monthTo?: number;
  dekadFrom?: number;
  dekadTo?: number;
  isClimateData?: boolean;
}

export type GeoJSONFeature = {
  type: "Feature";
  id?: string | number;
  geometry: {
    type: string;
    coordinates: any;
  };
  properties: {
    [key: string]: any;
  };
};

export type GeoJSONFeatureCollection = {
  type: "FeatureCollection";
  features: GeoJSONFeature[];
  crs?: {
    type: "name";
    properties: {
      name: string;
    };
  };
  bbox?: number[];
};

export interface QueryParams {
  latitude: number;
  longitude: number;
  zoom: number;
  sidebar: boolean;
  date: string | Date;
  detailsTab: string;
  layers: QueryLayer[];
  timeseries: string;
  geounit?: string;
  queryId?: string;
  country: string;
  agregation?: string;
  insuranceWindowMonth: string;
  insuranceWindowDekad: string;
  insuranceWindowMonthTo: string;
  insuranceWindowDekadTo: string;
  step: number;
  precipitation: boolean;
  soilMoisture: boolean;
  vegetation: boolean;
  precipitationData: boolean;
  soilMoistureData: boolean;
  vegetationData: boolean;
  droughtEvents: boolean;
  ipcScore: boolean;
  exitLevel?: number;
  trigerLevel?: number;
  maxPayout?: string;
  minPayout?: string;
  deductable?: string;
  sum_insured?: string;
  layerDate?: string;
  CQL_FILTER?: string;
  geographicalUnit?: string;
  minx?: number;
  miny?: number;
  maxx?: number;
  maxy?: number;
  financialLayers: {id: string; name:string}[]
  geographicalAreas: {id: string; name:string}[];
  geoJSONobject?: GetFeatureInfoResponse;
  legend:{ [key: string]: boolean; },
}

const useQueryParameters = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const currentQueryParams = extractQueryParameters();
  const queryParametersStrings = convertParamsToStrings(currentQueryParams);

  const updateLayerDate = (layerToUpdate: Partial<QueryLayer>) => {
    const updatedLayers = [...currentQueryParams.layers];

    const layerIndex = updatedLayers.findIndex(
      (updatedLayer) => updatedLayer.id === layerToUpdate.id
    );

    if (layerIndex > -1) {
      updatedLayers[layerIndex] = {
        ...updatedLayers[layerIndex],
        ...(layerToUpdate.date && { date: layerToUpdate.date }),
        ...(layerToUpdate.dateMode && { dateMode: layerToUpdate.dateMode }),
        ...(layerToUpdate.year && { year: layerToUpdate.year }),
      };

      setQueryParameters({ layers: updatedLayers });
    }
  };

  const updateAllLayerDate = (date: Date) => {
    const layers = [...currentQueryParams.layers].map((layer) => ({
      ...layer,
      date,
    }));

    setQueryParameters({ layers });
  };

  const setQueryParameters = (newParams: Partial<QueryParams>) => {
    const currentParams = extractQueryParameters();

    const updatedParams = { ...currentParams, ...newParams };
    const encodedParams = encodeQueryParams(updatedParams);

    navigate({
      pathname,
      search: `?options=${encodedParams}`,
    });
  };

  const createQueryParametersString = (
    overrides: Partial<QueryParams> = {}
  ) => {
    const queryParamsWithOverrides = {
      ...currentQueryParams,
      ...overrides,
    };

    const encodedParams = btoa(JSON.stringify(queryParamsWithOverrides));

    return `?options=${encodedParams}`;
  };

  const reorderLayers = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const previousLayers = [...currentQueryParams.layers];
      const [draggedLayer] = previousLayers.splice(dragIndex, 1);
      previousLayers.splice(hoverIndex, 0, draggedLayer);

      setQueryParameters({ layers: previousLayers });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentQueryParams.layers]
  );

  const toggleLayers = (layersToToggle: QueryLayer[], singleToggle: boolean) => {
    if (singleToggle) {
      return setQueryParameters({
        ...currentQueryParams,
        layers: currentQueryParams.layers[0]?.id === layersToToggle[0].id ? [] : layersToToggle,
        // legend: {
        //   ...currentQueryParams.legend,
        //   ...(layersToToggle[0]?.displayName ? { [layersToToggle[0].displayName]: true } : {})
        // },
      })
    }

    let updatedLayers = [...currentQueryParams.layers];

    layersToToggle.forEach((layer) => {
      const layerIndex = updatedLayers.findIndex(
        (updatedLayer) => updatedLayer.id === layer.id
      );

      if (layerIndex > -1) {
        updatedLayers = updatedLayers.filter(
          (updatedLayer) => updatedLayer.id !== layer.id
        );
      } else {
        updatedLayers.push(layer);
      }
    });

    setQueryParameters({
      ...currentQueryParams,
      layers: updatedLayers,
      timeseries: 'hide',
      zoom: 4.5,
      latitude: TARGET_COUNTRY[currentQueryParams.country].latitude,
      longitude: TARGET_COUNTRY[currentQueryParams.country].longitude + ((updatedLayers.length + 1) * (window.innerWidth/MAP_SHIFT_CONSTANT)),
    });
  };

  function convertParamsToStrings(params: QueryParams): { [key: string]: string } {
    const paramsAsStrings: { [key: string]: string } = {};
    for (const [key, value] of Object.entries(params)) {
      if (key === "layers") {
        paramsAsStrings[key] = JSON.stringify(value);
      } else if (Array.isArray(value)) {
        paramsAsStrings[key] = value.join(",");
      } else if (value instanceof Date) {
        paramsAsStrings[key] = value.toISOString();
      } else {
        paramsAsStrings[key] = value.toString();
      }
    }
    return paramsAsStrings;
  };

  function encodeQueryParams(params: QueryParams) {
    return Buffer.from(JSON.stringify(params)).toString("base64");
  }

  function decodeQueryParams(base64String: string) {
    return JSON.parse(Buffer.from(base64String, "base64").toString("utf-8"));
  }

  function extractQueryParameters(): QueryParams {
    const query = new URLSearchParams(search);
    const encodedParams = query.get("options");

    if (encodedParams) {
      const decodedParams = decodeQueryParams(encodedParams);

      try {
        if (decodedParams.layers && typeof decodedParams.layers === "string") {
          decodedParams.layers = JSON.parse(decodedParams.layers);
        }
      } catch (error) {
        console.error("Error parsing layers from query parameters:", error);
        decodedParams.layers = [];
      }

      return decodedParams;
    }

    return {
      latitude: 4.941597145954475,
      longitude: 52.79204410016732, // todo make it dynamic
      zoom: 5,
      sidebar: true,
      date: new Date(),
      detailsTab: "legends",
      layers: [layersData[0]],
      timeseries: "hide",
      geounit: '',
      country: "SOM",
      insuranceWindowMonth: "January",
      insuranceWindowDekad: "one",
      insuranceWindowMonthTo: "March",
      insuranceWindowDekadTo: "three",
      step: 1,
      precipitation: false,
      soilMoisture: false,
      vegetation: false,
      droughtEvents: false,
      ipcScore: false,
      precipitationData: false,
      soilMoistureData: false,
      vegetationData: false,
      agregation: "admin-level-2",
      exitLevel: 0.05,
      trigerLevel: 0.25,
      maxPayout: "57.1",
      minPayout: "8.00",
      deductable: "5.00",
      sum_insured: "100.0",
      layerDate: new Date().toDateString(),
      CQL_FILTER: "",
      geographicalUnit: "",
      minx: 0,
      miny: 0,
      maxx: 0,
      maxy: 0,
      financialLayers: [],
      geographicalAreas: [],
      geoJSONobject: {features:[]},
      legend: {},
    };
  }

  return {
    queryParameters: currentQueryParams,
    queryParametersStrings,
    createQueryParametersString,
    setQueryParameters,
    extractQueryParameters,
    reorderLayers,
    toggleLayers,
    updateAllLayerDate,
    updateLayerDate,
    decodeQueryParams,
  };
};

export default useQueryParameters;
