import React, { useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { 
  Box,
  CircularProgress,
  Paper, 
  Typography 
} from "@mui/material";
import useTimeseriesFinancial from "../../hooks/useTimeseriesFinancial";
import { UseQueryResult } from "react-query";

interface PayoutsChartProps {
  geographicalUnits?: string[];
  isGeographicalComparison?: boolean;
}
interface TimeseriesEntry {
  displayname: string;
  data: Array<[string, number]>;
}

const PayoutsChart: React.FC<PayoutsChartProps> = ({
  isGeographicalComparison = false,
}) => {
  const colors = ['#00A4EC', '#F06292', '#4CDBCF']

  const { data: timeseriesData, isLoading }: UseQueryResult<TimeseriesEntry[], unknown> =
    useTimeseriesFinancial();

  const groupedTimeSeries = useMemo(() => {
    let dataMap = new Map<string, any>();

    const allYears: string[] = Array.from(
      new Set(
        timeseriesData?.flatMap((anomaly) =>
          anomaly.data.map(([year]) => year.toString())
        )
      )
    );

    allYears.sort((a, b) => parseInt(a) - parseInt(b));

    allYears.forEach((yearStr) => {
      dataMap.set(yearStr, { year: yearStr });
    });

    timeseriesData?.forEach((anomaly) => {
      anomaly.data.forEach(([year, value]) => {
        const yearStr = year.toString();
        dataMap.get(yearStr)[anomaly.displayname] = value;
      });
    });

    return dataMap;
  }, [timeseriesData]);

  if (isLoading) return (
    <Box display="flex" justifyContent="center" height={500} width="100%" flexDirection="column" alignItems="center">
      <CircularProgress />
    </Box>
  )
  if (!timeseriesData) return null
  if (!timeseriesData?.length) return <div>No data available</div>;

  const anomalyNames = Array.from(
    new Set(timeseriesData?.map((anomaly) => anomaly.displayname))
  );

  return (
    <Paper style={{ padding: "20px" }}>
      <ResponsiveContainer width="100%" height={500}>
        <BarChart data={Array.from(groupedTimeSeries.values())}>
          <CartesianGrid strokeDasharray="3 3" />
          <Legend verticalAlign="top" align="left" wrapperStyle={{ paddingBottom: '24px' }} content={({ payload }) => {
            return (
              <Box display="flex" gap="10px">
                {payload?.map((label, index) => (
                  <Box display="flex" gap="5px" alignItems="center">
                    <Typography fontSize="12px">{label.value.split(' - ')[isGeographicalComparison ? 0 : 1]}</Typography>
                    <Box bgcolor={colors[index]} width="16px" height="16px" />
                  </Box>
                ))}
              </Box>
            )
          }} />
          <XAxis dataKey="year">
            <Label value="Year" position="insideBottom" offset={-5} />
          </XAxis>
          <YAxis
            label={{
              value: "Payouts in USD",
              angle: -90,
              position: "insideLeft",
            }}
          />
          <Tooltip />
          {anomalyNames.map((name, index) => (
            <Bar
              key={name}
              dataKey={name}
              fill={colors[index]}
              // name={name}
              barSize={20}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default PayoutsChart;
