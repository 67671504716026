import { getAxiosError } from "../helpers/src";

import axios from "axios";
import { getBackendURL } from "./backendURL";

export const startAndEndDate = async (
  monthFrom: number,
  dekadFrom: number,
  monthTo: number,
  dekadTo: number,
  groupId?: string
) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    const response = await axios.post(
      `${baseURL}/timeseries/get_dekad_start_end?group_id=${groupId}`,
      {
        startmonth: monthFrom,
        startdekat: dekadFrom,
        endmonth: monthTo,
        enddekat: dekadTo,
      }
    );

    const dates = {
      startdate: response.data[0].startdate,
      enddate: response.data[0].enddate,
    };
    return dates;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
