import { GetTimeseriesRequest } from "../types/timeseries";
import { getCountriesDefaultDekad } from "../services/countries";
import { useQuery } from "react-query";
import useQueryParameters from "./useQueryParameters";
import { getDekadName, getMonthName } from "../helpers/date";

export type UseTimeseries = GetTimeseriesRequest;

const useCountriesDefaultDekad = () => {
  const {
    queryParameters: { country },
    setQueryParameters,
  } = useQueryParameters();

  return useQuery(
    ["countries", country],
    () => getCountriesDefaultDekad(country),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled:
        Boolean(country) &&
        country !== "ALL" &&
        window.location.pathname.includes("get-started"),
      onSuccess: (data) => {
        setQueryParameters({
          insuranceWindowDekad: getDekadName(data?.defaultdekad.startdekad),
          insuranceWindowDekadTo: getDekadName(data?.defaultdekad.enddekad),
          insuranceWindowMonth: getMonthName(data?.defaultdekad.startmonth),
          insuranceWindowMonthTo: getMonthName(data?.defaultdekad.endmonth),
        });
      },
    }
  );
};

export default useCountriesDefaultDekad;
