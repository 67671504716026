import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Home from "../views/Home";
import About from "../views/About/About";
import DisclaimerAndGuide from "../views/DisclaimerAndGuide";
import Dashboard from "../views/Dashboard";
import GetStarted from "../views/GetStarted";
import DroughtRisk from "../views/DroughtRisk/DroughtRisk";
import DroughtIndices from "../views/DroughtIndices";
import FinancialIndices from "../views/FinancialIndices";

export function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<Navigate to="/home" replace />}
        />
        <Route path="/home" element={<Home />} />
        <Route path="/disclaimer" element={<DisclaimerAndGuide />} />
        <Route path="/:dashboard" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
        <Route path="/introduction" element={<About />} />
        <Route path="/dashboard/get-started" element={<GetStarted />} />
        <Route path="/dashboard" element={<GetStarted />} />
        <Route path="/dashboard/drought-risk" element={<DroughtRisk />} />
        <Route path="/dashboard/drought-indices" element={<DroughtIndices />} />
        <Route path="/dashboard/financial-indices" element={<FinancialIndices />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
