import { Box } from "@mui/material";
import logo from "../../assets/logo.svg";
import logo2 from "../../assets/logo-2.svg";

const Header = () => (
  <Box bgcolor="#002244" height="90px"  display="flex" justifyContent="space-between" alignItems="center" >
    <Box paddingBottom="8px">
      <img width="200px" height="82px" alt="Logo" src={logo} />
    </Box>
    <Box marginRight="20px" bgcolor="white">
      <img height="60px" alt="Logo2" src={logo2} />
    </Box>
  </Box>
);

export default Header;
