import { Box, Typography, Stack } from '@mui/material';

import { LineProps } from './types';

const Line = ({
  ruleTitle,
  stroke,
  'stroke-width': strokeWidth,
  'stroke-opacity': strokeOpacity,
  'stroke-linecap': strokeLinecap,
  'stroke-linejoin': strokeLinejoin,
}: LineProps) => {
  return (
    <Box>
      <Stack direction="row" spacing="0.5rem" alignItems="center">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <svg width="1rem" height="1rem">
            <line
              x1="0"
              y1="0.5rem"
              x2="1rem"
              y2="0.5rem"
              stroke={stroke}
              strokeWidth={strokeWidth}
              strokeOpacity={strokeOpacity}
              strokeLinecap={strokeLinecap}
              strokeLinejoin={strokeLinejoin}
            />
          </svg>
        </Box>
        <Typography variant="body2" fontSize="0.7rem">
          {ruleTitle}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Line;
