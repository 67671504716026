import { getAxiosError } from "../helpers/src";

import axios from "axios";
import { getBackendURL } from "./backendURL";

export const getCountryGeoJSON = async () => {
  try {
    const getURL = await getBackendURL();
    const baseURL = getURL.geoserverURL;
    const adminOneUrl = `${baseURL}/geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adm0-geounits&outputFormat=application/json`;

    const response = await axios.get(adminOneUrl);

    return response.data;
  } catch (error) {
    console.log(error, "error");
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};

export const getAdminOneGeoJSON = async () => {
  const getURL = await getBackendURL();
  const baseURL = getURL.geoserverURL;

  try {
    const adminOneUrl = `${baseURL}/geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adm1-geounits&outputFormat=application/json`;

    const response = await axios.get(adminOneUrl);

    return response.data;
  } catch (error) {
    console.log(error, "error");
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};

export const getAdminTwoGeoJSON = async () => {
  const getURL = await getBackendURL();
  const baseURL = getURL.geoserverURL;

  try {
    const adminOneUrl = `${baseURL}/geoserver/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=adm2-geounits&outputFormat=application/json`;

    const response = await axios.get(adminOneUrl);

    return response.data;
  } catch (error) {
    console.log(error, "error");
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
