import { ReactNode } from "react";
import { useLocation } from "react-router-dom";

import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import FiltersSidebar from "../FiltersSidebar";

import Header from "../Header";
import Sidebar from "../Sidebar/Sidebar";

import Footer from "../Footer";
import useQueryParameters from "../../hooks/useQueryParameters";

interface LayoutProps {
  children: ReactNode;
  withHeader?: boolean;
  withFooter?: boolean;
  next?: () => void;
  back?: () => void;
  nextTitle?: string;
  backTitle?: string;
  disableNext?: boolean | (() => boolean);
  disableBack?: boolean;
  withHiddenOverflow?: boolean;
  hasOverflow?: boolean;
  showFilterSidebar?: boolean;
  showCollapseSidebar?: boolean;
}

const Layout = ({
  withFooter = true,
  showFilterSidebar,
  showCollapseSidebar = true,
  children,
  next,
  back,
  nextTitle,
  backTitle,
  disableNext,
  disableBack,
}: LayoutProps) => {
  const { pathname } = useLocation();
  const { queryParameters: { sidebar }, setQueryParameters } =
    useQueryParameters()
    
  const openFilterSidebar = (value: boolean) => {
    setQueryParameters({
      sidebar: value,
    })
  } 

  if (!pathname) return null;

  return (
    <Box display="flex" flexDirection="column" height="100vh" overflow="hidden">
      <Header />
      <Box display="flex" flex="1" className="item2" overflow="hidden">
        <Box overflow="auto" sx={{ 
           '&::-webkit-scrollbar': {
            width: '1px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#002244',
          }
         }}>
          <Sidebar />
        </Box>
        <Box display="flex" flexDirection="column" flex="1" overflow="hidden">
          <Box 
            overflow="auto" 
            flex="1"
            display="flex"
            justifyContent={showFilterSidebar ? "flex-start" : "center"}
            sx={{ 
              '&::-webkit-scrollbar': {
                width: '1px'
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#002244',
              },
              overflowX: 'hidden',
            }}
          >
            {showFilterSidebar && 
            <Box>
              {showCollapseSidebar && <IconButton 
              sx={{
                position: "absolute", 
                zIndex: 4, 
                backgroundColor: "#F2F2F2", 
                transform: `translate(${sidebar ? '305px' : '0px'}, 33vh)`,
                borderRadius: "0 6px 6px 0",
                padding: "5px", 
                width: "29px", 
                height: "80px",
                '&:hover': {
                  backgroundColor: "#d8d8d8", 
                }
              }}
              onClick={() => openFilterSidebar(!sidebar)}
              >
                {sidebar ? <ChevronLeft /> : <ChevronRight />}
              </IconButton>}
              {(sidebar || !showCollapseSidebar) && <FiltersSidebar children={undefined} />}    
            </Box>}
            <Box flex={1} maxHeight="calc(100vh - 155px)">
              {children}
            </Box>
          </Box>
          {withFooter &&<Footer
            next={next}
            back={back}
            nextTitle={nextTitle}
            backTitle={backTitle}
            disableNext={disableNext}
            disableBack={disableBack}
          />}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
