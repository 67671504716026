import { useQuery } from "react-query";
import { getAdminOneGeoJSON, getAdminTwoGeoJSON, getCountryGeoJSON } from "../services/geoJSON";

export const useCountryGeoJSON = () => {
  try {
    const ipcScoreResponse = useQuery(
      ["countryGeoJSON"],
      async () => await getCountryGeoJSON(),
      {
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    return ipcScoreResponse?.data;
  } catch (error) {
    console.error("Error in useIpcScore:", error);
    throw error;
  }
};

export const useAdminOneGeoJSON = () => {
  try {
    const ipcScoreResponse = useQuery(
      ["adminOneGeoJSON"],
      async () => await getAdminOneGeoJSON(),
      {
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    return ipcScoreResponse?.data;
  } catch (error) {
    console.error("Error in useIpcScore:", error);
    throw error;
  }
};

export const useAdminTwoGeoJSON = () => {
  try {
    const ipcScoreResponse = useQuery(
      ["adminTwoGeoJSON"],
      async () => await getAdminTwoGeoJSON(),
      {
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      }
    );

    return ipcScoreResponse?.data;
  } catch (error) {
    console.error("Error in useIpcScore:", error);
    throw error;
  }
};
