import { Box, ListItem, Typography, Stack } from "@mui/material";

import { CardProps } from "./types";
import Layer from "../Layer";

const Card = ({ layers, openDialog }: CardProps) => {
  return (
    <ListItem
      divider
      disableGutters
      disablePadding
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "inherit",
        padding: "1rem",
        paddingTop: 0,
        border: 0,
      }}
    >
      <Stack
        direction="column"
        spacing="0.5rem"
        sx={{
          width: "100%",
        }}
      >
        {layers?.map((layer) => (
          <Layer key={layer.id} {...layer} openDialog={openDialog} />
        ))}
      </Stack>
    </ListItem>
  );
};

export default Card;
