import axios, { AxiosError } from 'axios';

export const isAxiosError = axios.isAxiosError;

export const getAxiosError = (err: unknown) => {
  const error = err as Error | AxiosError;

  if (isAxiosError(error)) {
    const status = error.response?.status;
    const errors = error.toJSON();

    if (status === 401 || status === 403) {
      const redirectTo = sessionStorage.getItem('returnUrl');
      if (redirectTo) {
        const url = redirectTo.replace('"', '').replace('"', '');
        window.location.href = url;
      }
    }

    console.log(errors);
    return errors;
  } else {
    return {};
  }
};
