import { useQuery } from "react-query";
import { getFeatureBboxSubarea } from "../services/featureBbox";
import useQueryParameters from "./useQueryParameters";

const useBboxSubarea = () => {
  const { queryParameters: { geographicalUnit, queryId, agregation }, setQueryParameters } = useQueryParameters();

  const featureId = queryId?.split('.')?.[1] ?? ''

  return useQuery(["featureInfoBbox", geographicalUnit, queryId], () => getFeatureBboxSubarea(featureId), {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!queryId && agregation === 'admin-level-2',
    onSuccess: (data) => {
      setQueryParameters({ geoJSONobject: {
        features: data?.featurecollection?.features ?? ''}
      })
    }
  });
};

export default useBboxSubarea;
