import axios from "axios";

export const getBackendURL = async () => {
  const data = {
    backendURL: "https://ngdi-api.financialprotectionforum.org",
    geoserverURL: "https://geoserver.financialprotectionforum.org",
  };

  try {
    const response = await axios.get("/getBackendURL");
    return response.data;
  } catch (error) {
    return data;
  }
};
