import { Box, Typography } from "@mui/material";
import Layout from "../../Components/Layout";
import Hero from "../../Components/Hero";
import PageContent from "../../Components/Content/PageContent";
import { useNavigate } from "react-router-dom";
import { useAdminOneGeoJSON, useAdminTwoGeoJSON } from "../../hooks/useGeoJSON";

const userGuideVideo = require('../../assets/videos/user-guide.mp4')
const thumbnail = require('../../assets/videos/thumbnail.png')

const Home = () => {
  const navigate = useNavigate();

  useAdminOneGeoJSON()
  useAdminTwoGeoJSON()

  return (
    <Layout
      next={() => navigate("/dashboard/get-started")}
      back={() => navigate("/disclaimer")}
      nextTitle="Get started"
      backTitle="Go to User Guide"
      hasOverflow={false}
      showFilterSidebar={false}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Hero
          headline="Welcome to the NGDI Dashboard"
          description="This platform provides a suite of tools to amplify your technical expertise in drought indices while fostering a deeper understanding of their dynamics. Here, you are the architect of your own drought indices: you are able to access to a range of satellite-derived climate variables, visualize their performance, and in turn develop disaster risk financing products better designed to meet your objectives. With an initial focus on the Horn of Africa, this dashboard opens the doors to drought index exploration for a range of risk finance applications, offering transparency and control for the next generation of drought index development."
        >
          <Box
            sx={{
              paddingTop: "48px",
              textAlign: "center",
            }}
          >
            <Typography
              variant="h1"
              fontSize="24px"
              lineHeight="24px"
              textAlign="left"
            >
              Disclaimer
            </Typography>
            <Typography  textAlign="left" paddingTop="24px">
              The Next Generation Drought Index Dashboard ("the tool") is a
              capacity building tool. It has been developed by the World Bank to
              amplify your technical expertise in drought indices while
              fostering a deeper understanding of their dynamics. The tool is
              provided for informational purposes only and does not constitute
              legal or scientific advice or service. The World Bank makes no
              warranties or representations, express or implied as to the
              accuracy or reliability of the tool or the data contained therein.
              A user of the tool should seek qualified expert advice for
              specific diagnosis and analysis of a particular project. Any use
              thereof or reliance thereon is at the sole and independent
              discretion and responsibility of the user. No conclusions or
              inferences drawn from the tool should be attributed to the World
              Bank, its Board of Executive Directors, its Management, or any of
              its member countries. This tool does not imply any judgment or
              endorsement on the part the World Bank. In no event will the World
              Bank be liable for any form of damage arising from the application
              or misapplication of the tool, or any associated materials.
            </Typography>
          </Box>
          <PageContent>
            <video preload="metadata" width="632px" height="340px" controls poster={thumbnail}>
              <source src={userGuideVideo} type="video/mp4" />
            </video>
          </PageContent>
          <Typography variant="subtitle1" textAlign="left" paddingTop="30px">
            If you have more questions about the tool: Visit User Guide section
          </Typography>
        </Hero>
      </Box>
    </Layout>
  );
};

export default Home;
