import { forwardRef } from "react";
import ReactEchartsCore from "echarts-for-react/lib/core";

import * as echarts from "echarts/core";
import { LineChart, BarChart, ScatterChart } from "echarts/charts";

import {
  GridComponent,
  ToolboxComponent,
  TooltipComponent,
  AxisPointerComponent,
  TitleComponent,
  TimelineComponent,
  MarkLineComponent,
  MarkAreaComponent,
  LegendComponent,
  LegendScrollComponent,
  LegendPlainComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
} from "echarts/components";

import { CanvasRenderer } from "echarts/renderers";

import theme from "./libs/theme";

echarts.registerTheme("DHI", theme);

echarts.use([
  BarChart,
  LineChart,
  ScatterChart,
  GridComponent,
  CanvasRenderer,
  ToolboxComponent,
  AxisPointerComponent,
  TooltipComponent,
  TitleComponent,
  TimelineComponent,
  MarkLineComponent,
  MarkAreaComponent,
  LegendComponent,
  LegendScrollComponent,
  LegendPlainComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
]);

const defaultOp = {
  animation: false,
  xAxis: {},
  yAxis: {},
  series: [],
};

export interface ChartProps {
  option: any;
  onLegendSelectChanged: (event: any) => void;
}

const Chart = forwardRef(({ option, onLegendSelectChanged }: ChartProps, ref) => {
  return (
    <ReactEchartsCore
      notMerge={true}
      style={{
        width: "100%",
        minWidth: "100%",
        maxWidth: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: "100%",
      }}
      echarts={echarts}
      option={{ ...defaultOp, ...option }}
      theme="DHI"
      lazyUpdate
      onEvents={{ 
        legendselectchanged: onLegendSelectChanged,
      }}
    />
  );
});

export default Chart;
