import { getAxiosError } from "../helpers/src";

import axios from "axios";
import { getBackendURL } from "./backendURL";

export const getCountries = async () => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    const response = await axios.get(`${baseURL}/features/countries`);

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};

export const getCountriesDefaultDekad = async (countryCode: string) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    const response = await axios.get(
      `${baseURL}/features/countries_default_dekad?countrycode=${countryCode}`
    );

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
