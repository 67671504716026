import { getAxiosError } from "../helpers/src";
import { GetTimeseriesRequest } from "../types/timeseries";
import axios from "axios";
import { getBackendURL } from "./backendURL";

export const getFinancialTimeseriesByFeature = async ({
  financialLayers,
  geographicalAreas,
  monthFrom,
  monthTo,
  dekadFrom,
  dekadTo,
  exitLevel,
  trigerLevel,
  maxPayout,
  minPayout,
  deductable,
  sum_insured,
}: GetTimeseriesRequest) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    const payload = {
      startmonth: monthFrom,
      startdekat: dekadFrom,
      endmonth: monthTo,
      enddekat: dekadTo,
      trigger_level: trigerLevel,
      exit_level: exitLevel,
      max_payout: maxPayout,
      min_payout: minPayout,
      deductable: deductable,
      sum_insured: sum_insured,
    };

    const groupIds = financialLayers
      ?.map(({ id }) => `group_id=${id}`)
      .join("&");
    const featureIds = geographicalAreas
      ?.map(({ id }) => `feature_id=${id}`)
      .join("&");

    const urlTOFetch = `${baseURL}/payout/get_historical_payouts?${featureIds}&${groupIds}`;

    const response = await axios.post(urlTOFetch, payload);

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};

export const getFinancialTimeseriesExcelByFeature = async ({
  financialLayers,
  geographicalAreas,
  monthFrom,
  monthTo,
  dekadFrom,
  dekadTo,
  exitLevel,
  trigerLevel,
  maxPayout,
  minPayout,
  deductable,
  sum_insured,
}: GetTimeseriesRequest) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    const groupIds = financialLayers
      ?.map(({ id }) => `group_id=${id}`)
      .join("&");
    const featureIds = geographicalAreas
      ?.map(({ id }) => `feature_id=${id}`)
      .join("&");

    const response = await axios.post(
      `${baseURL}/payout/download_historical_payouts?${featureIds}&${groupIds}`,
      {
        startmonth: monthFrom,
        startdekat: dekadFrom,
        endmonth: monthTo,
        enddekat: dekadTo,
        trigger_level: trigerLevel,
        exit_level: exitLevel,
        max_payout: maxPayout,
        min_payout: minPayout,
        deductable: deductable,
        sum_insured: sum_insured,
      },
      {
        responseType: "blob",
      }
    );

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
