import { Box } from "@mui/material";
import Layout from "../../Components/Layout";
import Hero from "../../Components/Hero";
import DashboardSteps from "../../Components/DashboardSteps";
import useQueryParameters from "../../hooks/useQueryParameters";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const GetStarted = () => {
  const { queryParameters, createQueryParametersString, setQueryParameters } = useQueryParameters();
  const {
    agregation,
    step,
    country,
    insuranceWindowDekad,
    insuranceWindowMonth,
    insuranceWindowDekadTo,
    insuranceWindowMonthTo,
  } = queryParameters;
  const navigate = useNavigate();

  useEffect(() => {
    if (step === undefined || step === null) {
      setQueryParameters({
        ...queryParameters,
        step: 1,
      });
    }
  }, []);

  const handleNext = () => {
    if (step < 3) {
      setQueryParameters({
        step: queryParameters.step + 1,
      });
    } else {
        const queryParameters = createQueryParametersString({
          timeseries: "hide",
          geographicalUnit: '',
          queryId: "",
        });
    
        navigate({
          pathname: '/dashboard/drought-risk',
          search: queryParameters,
        });
    }
  };

  const handleBack = () => {
    setQueryParameters({
      step: queryParameters.step - 1,
    });
  };

  const disableNextButton = () => {
    if (step === 1) return !country;
    if (step === 2) return !agregation;
    if (step === 3)
      return (
        !insuranceWindowDekad ||
        !insuranceWindowMonth ||
        !insuranceWindowDekadTo ||
        !insuranceWindowMonthTo
      );
    return false;
  };

  return (
    <Layout
      next={handleNext}
      back={handleBack}
      disableNext={() => disableNextButton()}
      disableBack={step === 1}
      nextTitle="Next"
      backTitle="Back"
      hasOverflow={false}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Hero
          headline="Get started"
          description="Define your target area and period to make using the dashboard easier for you."
        >
          <DashboardSteps />
        </Hero>
      </Box>
    </Layout>
  );
};

export default GetStarted;
