import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  styled,
  AccordionSummaryProps,
  FormControl,
  RadioGroup,
  MenuItem,
  TextField,
  Autocomplete,
  Radio,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Layout from "../../Components/Layout";

import useQueryParameters from "../../hooks/useQueryParameters";
import React from "react";
import PayourChart from "../../Components/PayoutsChart/PayoutsChart";
import useArea from "../../hooks/useArea";

import { layersData } from "../../helpers/src/Layersstore";
import { getFinancialTimeseriesExcelByFeature } from "../../services/financialTimeseries";
import { getDecadeNumber, getMonthNumber } from "../../hooks/useLayers";
import { useNavigate } from "react-router";

import useAdminOne from "../../hooks/useAdminOne";
import useCountries from "../../hooks/useCountries";
import {
  exitLevelOptions,
  isValidNumberInput,
  isBetweenRange,
} from "../../helpers/financialIndicesHelper";
import DialogInfo from "../../Components/Dialog/DialogInfo";
import { Info } from "@mui/icons-material";

const FinancialIndices = () => {
  const navigate = useNavigate();

  const { queryParameters, setQueryParameters, createQueryParametersString } = useQueryParameters();
    
  const [selectedExitLevel, setSelectedExitLevel] = useState<number>(0.2);
  const [selectedTriggerLevel, setTriggerExitLevel] = useState<number>(0.5);
  const [comparisonType, setComparisonType] = useState("droughtIndices");
  const [isLoading, setIsLoading] = useState(true) // todo autocomplete need to be reset

  const { data: adminLevelOneData } = useAdminOne(queryParameters?.country);
  const { data: adminLevelTwoData } = useArea(queryParameters?.country);
  const { data: countryData } = useCountries();

  let geographicalUnitList: any[] = [];
  if (queryParameters.agregation === "admin-level-1") {
    geographicalUnitList = adminLevelOneData?.sort(
      (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)
      ) ?? [];
    } else if (queryParameters.agregation === "admin-level-2") {
    geographicalUnitList = adminLevelTwoData?.sort(
      (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)
      ) ?? [];
    } else if (queryParameters.agregation === "country") {
    geographicalUnitList = countryData?.sort(
      (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)
    ) ?? [];
  }

  const [typeOfComparisonExpanded, setTypeOfComparisonExpanded] =
    React.useState(true);
  const [payoutParametersExpanded, setPayoutParametersExpanded] =
    React.useState(false);
  const [dialogContent, setDialogContent] = useState<string>('')

  const handleOpenDialog = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {
    e.stopPropagation()
    setDialogContent(type)
  }

  const handleTypeOfComparisonChange = () => {
    setTypeOfComparisonExpanded(!typeOfComparisonExpanded);
  };

  const handlePayoutParametersChange = () => {
    setPayoutParametersExpanded(!payoutParametersExpanded);
  };

  const handleGeographicalAreasChange = (
    event: React.ChangeEvent<{}>,
    value: {id: string; name: string}[] | null,
  ) => {      
    setQueryParameters({ geographicalAreas: value ? value : [] });
  };

  const handleLayerChange = (
    event: React.ChangeEvent<{}>,
    value: {id: string; name: string}[] | null
  ) => {
    setQueryParameters({ financialLayers: value ? value : [] });
  };

  const handleBack = () => {
    const pathname = "/dashboard/drought-indices";
    const queryParameters1 = createQueryParametersString({
      timeseries: "hide",
      queryId: "",
      step: 1,
    });

    navigate({
      pathname: `${pathname}`,
      search: queryParameters1,
    });
  };

  const handleDownloadExcel = async () => {
    try {
      const excelData = await getFinancialTimeseriesExcelByFeature({
        financialLayers: queryParameters.financialLayers,
        geographicalAreas: queryParameters.geographicalAreas,
        monthFrom: getMonthNumber(queryParameters.insuranceWindowMonth),
        monthTo: getMonthNumber(queryParameters.insuranceWindowMonthTo),
        dekadFrom: getDecadeNumber(queryParameters.insuranceWindowDekad),
        dekadTo: getDecadeNumber(queryParameters.insuranceWindowDekadTo),
        exitLevel: queryParameters.exitLevel,
        trigerLevel: queryParameters.trigerLevel,
        maxPayout: Number(queryParameters.maxPayout),
        minPayout: Number(queryParameters.minPayout),
        deductable: Number(queryParameters.deductable),
        sum_insured: Number(queryParameters.sum_insured),
      });

      const url = window.URL.createObjectURL(
        new Blob([excelData], { type: "application/octet-stream" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PayoutData.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error processing ZIP file:", error);
    }
  };

  const handleExitLevelChange = (event: ChangeEvent<{ value: unknown }>) => {
    const selectedValue = event.target.value as number;
    setSelectedExitLevel(selectedValue);
    setQueryParameters({ exitLevel: selectedValue });
  };

  const handleTriggerLevelChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedValue = event.target.value as number;
    setTriggerExitLevel(selectedValue);
    setQueryParameters({ trigerLevel: selectedValue });
  };

  const handleComparisonTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.value === "geographicalUnits") {
      setQueryParameters({ financialLayers: queryParameters.financialLayers.length === 0 ? [] : [queryParameters.financialLayers[0]] });
    } else {
      setQueryParameters({ geographicalAreas: queryParameters.geographicalAreas.length === 0 ? [] : [queryParameters.geographicalAreas[0]] });
    }
    
    setComparisonType(event.target.value);
  };

  useEffect(() => {
    setQueryParameters({ 
      financialLayers: queryParameters.layers.map(({ timeseriesGroupId, displayName }) => ({ id: timeseriesGroupId ?? '', name: displayName ?? ''  })),
      geographicalAreas: queryParameters.geographicalUnit ? [{ id: queryParameters.queryId?.split(".")[1] ?? '', name: queryParameters.geographicalUnit ?? ''}] : [],
    })
    setIsLoading(false);
  }, [])

  return (
      <Layout
        next={handleDownloadExcel}
        back={handleBack}
        nextTitle="Download"
        backTitle="Back"
        showFilterSidebar={true}
        showCollapseSidebar={false}
      >
        <Box
          sx={{
            maxHeight: '100%',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              width: '1px'
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#002244',
            },
          }}
        >
          <Box
          sx={{
            margin: '25px 50px'
          }}>
              <Box marginBottom="36px">
                <Typography fontSize="34px"  marginBottom="12px">Simulate historical payout</Typography>
                <Typography>
                  Set your focus of your comparison and define the payout parameters, then the chart with 
                  historical payouts will be automatically updated. Finally, you can download the results.
                </Typography>
              </Box>
              <Box marginBottom="48px">
                <Box marginBottom="12px">
                  <Typography marginBottom="12px" fontSize="18px" fontWeight={550}>Define focus of comparison</Typography>
                  <Typography>
                  You can base your analysis on different comparisons. Either you want to compare 
                  multiple drought indices in one geographical unit or you want to compare multiple 
                  geographical units with one drought index.
                  </Typography>
                </Box>
                <Box marginLeft="20px">
                  <FormControl>
                    <RadioGroup
                      value={comparisonType}
                      onChange={handleComparisonTypeChange}
                    >
                      <FormControlLabel
                        value="droughtIndices"
                        control={<Radio />}
                        label={
                          <Box display="flex" gap="5px" alignItems="center">
                            <Typography>Compare different drought indices</Typography>
                            <IconButton size="small" onClick={(e) => handleOpenDialog(e, 'compareDiffrentDroughtIndices')}>
                              <Info fontSize="small"/>
                            </IconButton>
                          </Box>
                        }
                      />
                      {comparisonType === "droughtIndices" && (
                        <Box display="flex" flexDirection="row" gap="10px" marginTop="10px" marginBottom="20px">
                          <Autocomplete
                            sx={{ width: "268px" }}
                            isOptionEqualToValue={(option, value) => option.name === value?.name}
                            options={layersData.map(({ timeseriesGroupId, displayName }) => ({id: timeseriesGroupId, name: displayName }))}
                            getOptionLabel={option => option.name ?? ''}
                            getOptionDisabled={option => Boolean(queryParameters.financialLayers.find(area => area.id === option.id))}
                            value={queryParameters.financialLayers}
                            onChange={handleLayerChange}
                            multiple
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Drought Indices"
                                placeholder="Drought Indices"
                                autoComplete="off"
                              />
                            )}
                          />

                          {!isLoading && (<Autocomplete
                            sx={{ width: "268px" }}
                            onChange={(e, value) => handleGeographicalAreasChange(e, value ? [value] : null)}
                            getOptionLabel={option => option?.name ?? ''}
                            value={queryParameters.geographicalAreas[0]}
                            options={geographicalUnitList}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Geographical unit"
                                placeholder="Geographical unit"
                              />
                            )}
                          />)}
                        </Box>
                      )}
                      <FormControlLabel
                        value="geographicalUnits"
                        control={<Radio />}
                        label={
                          <Box display="flex" gap="5px" alignItems="center">
                            <Typography>Compare different geographical units</Typography>
                            <IconButton size="small" onClick={(e) => handleOpenDialog(e, 'compareDiffrentGeographicalUnits')}>
                              <Info fontSize="small"/>
                            </IconButton>
                          </Box>
                        }
                      />

                      {comparisonType === "geographicalUnits" && (
                        <Box display="flex" flexDirection="row" gap="10px" marginTop="10px">
                          <Autocomplete
                            sx={{ width: "268px" }}
                            options={layersData.map(({ timeseriesGroupId, displayName }) => ({id: timeseriesGroupId, name: displayName }))}
                            getOptionLabel={option => option?.name ?? ''}
                            value={queryParameters.financialLayers[0]}
                            onChange={(e, value) => handleLayerChange(e, value ? [value] : null)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Drought Indices"
                                placeholder="Drought Indices"
                              />
                            )}
                          />
                          <Box display={"flex"} flexDirection={"column"}>
                            <Autocomplete
                              sx={{
                                width: "268px",
                                ".MuiOutlinedInput-root": { borderColor: "red" },
                              }}
                              multiple
                              onChange={handleGeographicalAreasChange}
                              value={queryParameters.geographicalAreas}
                              getOptionLabel={option => option?.name ?? ''}
                              getOptionDisabled={option => Boolean(queryParameters.geographicalAreas.length >= 3 || queryParameters.geographicalAreas.find(area => area.id === option.id))}
                              options={geographicalUnitList}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Area"
                                  placeholder="Area"
                                />
                              )}
                            />
                          </Box>
                        </Box>
                      )}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
              <Box marginBottom="48px">
                <Box marginBottom="12px">
                  <Typography marginBottom="12px" fontSize="18px" fontWeight={550}>Define payout parameters</Typography>
                  <Typography>
                    You can simulate the historical payout analysis based on a number of parameters you define yourself.
                  </Typography>
                </Box>
                  <Box
                    display="flex"
                    gap="20px"
                    width="48%"
                    paddingBottom="20px"
                  >
                    <TextField
                      sx={{ flex: '1' }}
                      select
                      label={
                        <Tooltip title="Frequency of the event that will trigger the payout.​">
                          <Typography>Trigger Level</Typography> 
                        </Tooltip>
                      }
                      value={selectedTriggerLevel}
                      onChange={(event: ChangeEvent<{ value: unknown }>) =>
                      handleTriggerLevelChange(event)
                    }
                    >
                      {exitLevelOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value} disabled={Number(option.value) <= Number(selectedExitLevel)}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>

                    <TextField
                      sx={{ flex: '1' }}
                      label={
                        <Tooltip title="Frequency of the event for which maximum payout is reached.​​">
                          <Typography>Exit Level</Typography> 
                        </Tooltip>
                      }
                      select
                      value={selectedExitLevel}
                      onChange={(event: ChangeEvent<{ value: unknown }>) =>
                        handleExitLevelChange(event)
                      }
                    >
                      {exitLevelOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value} disabled={Number(option.value) >= Number(selectedTriggerLevel)}>
                          {option.label} {Boolean(option.value > selectedTriggerLevel)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>

                  <Box display="flex" gap="20px">
                    <TextField
                      label={
                        <Tooltip title="Total value insured in USD.​​​">
                          <Typography>Sum Insured</Typography> 
                        </Tooltip>
                      }
                      value={queryParameters.sum_insured}
                      onChange={(e) => {
                        if (isValidNumberInput(e.target.value)) {
                          setQueryParameters({
                            ...queryParameters,
                            sum_insured: e.target.value,
                          });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography fontSize="24px">$</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label={
                        <Tooltip title="Percentage of the Sum insured that will be deducted from the payout.​​​">
                          <Typography>Deductible</Typography> 
                        </Tooltip>
                      }
                      value={queryParameters.deductable}
                      onChange={(e) => {
                        if (isValidNumberInput(e.target.value) && isBetweenRange(Number(e.target.value), 0, 100)) {
                          setQueryParameters({
                            ...queryParameters,
                            deductable: e.target.value,
                          });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography fontSize="24px">%</Typography>
                          </InputAdornment>
                        ),
                      }}
                      inputProps={{
                        min: 0,
                        max: 100,
                        maxLength: 5, 
                      }}
                    />

                    <TextField
                      label={
                        <Tooltip title="Percentage of the Sum insured that needs to be reached to trigger a payout.​​​​">
                          <Typography>Minimum Payout</Typography> 
                        </Tooltip>
                      }
                      value={queryParameters.minPayout}
                      onChange={(e) => {
                        if (isValidNumberInput(e.target.value) && isBetweenRange(Number(e.target.value), 0, 100)) {
                          setQueryParameters({
                            ...queryParameters,
                            minPayout: e.target.value,
                          });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography fontSize="24px">%</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label={
                        <Tooltip title="Percentage of the Sum insured that cannot be exceeded for the payout.​​">
                          <Typography>Maximum Payout</Typography> 
                        </Tooltip>
                      }
                      value={queryParameters.maxPayout}
                      onChange={(e) => {
                        if (isValidNumberInput(e.target.value) && isBetweenRange(Number(e.target.value), 1, 100)) {
                          setQueryParameters({
                            ...queryParameters,
                            maxPayout: e.target.value,
                          });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography fontSize="24px">%</Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
              </Box>
          </Box>  
          <Box height={500} margin="25px 50px">
            <Typography fontSize="18px" fontWeight={550} paddingY="5px">Historical payouts analysis</Typography>
            <Box sx={{ border: "1px solid #ccc" }}>
              <PayourChart isGeographicalComparison={comparisonType === 'geographicalUnits'} />
            </Box>
          </Box>
        </Box>
        <DialogInfo item={dialogContent} changeItem={setDialogContent} />
      </Layout>
  );
};

export default FinancialIndices;

