import { stringify } from "querystring";

import { getAxiosError } from "../helpers/src";

import {
  GetFeatureInfoRequest,
  GetFeatureInfoResponse,
} from "../types/featureInfo";

import api from "./index";

import {
  getAllMonths,
  getDecadeNumber,
  getFirstDayOfDecade,
  getMonthNumber,
  getMonthsBetween,
  isAcrossYear,
} from "../hooks/useLayers";
import { getBackendURL } from "./backendURL";

export const getFeatureInfo = async ({
  bbox: BBOX,
  height: HEIGHT,
  layerId: LAYER_ID,
  width: WIDTH,
  x: I,
  y: J,
  date,
  queryParameters,
}: GetFeatureInfoRequest) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.geoserverURL;

  const periodStart = getMonthNumber(
    queryParameters.insuranceWindowMonth.toString()
  );
  const periodEnd = getMonthNumber(
    queryParameters.insuranceWindowMonthTo.toString()
  );

  const monthsBetween = getMonthsBetween(periodStart, periodEnd);
  const monthsBetweenParam = monthsBetween.length > 0 ? monthsBetween : null;
  const allMonths = getAllMonths(periodStart, periodEnd);
  const decadeFrom = getDecadeNumber(queryParameters.insuranceWindowDekad);
  const decadeTo = getDecadeNumber(queryParameters.insuranceWindowDekadTo);
  const decadeDayFrom = getFirstDayOfDecade(decadeFrom);
  const decadeDayTo = getFirstDayOfDecade(decadeTo);
  const datesAccrosYear = isAcrossYear(
    queryParameters.insuranceWindowMonth,
    queryParameters.insuranceWindowMonthTo
  );

  const periodParameter = datesAccrosYear ? "11\%5C,12" : allMonths;
  try {
    const queryParametersFeature = stringify({
      BBOX: `${BBOX[1]},${BBOX[0]},${BBOX[3]},${BBOX[2]}`,
      CRS: "EPSG:4326",
      FORMAT: "image/png",
      HEIGHT,
      I,
      INFO_FORMAT: "application/json",
      J,
      LAYERS: LAYER_ID + `_${queryParameters.agregation}`,
      // PROPERTYNAME: getPropertyName(LAYER_ID),
      QUERY_LAYERS: LAYER_ID + `_${queryParameters.agregation}`,
      REQUEST: "GetFeatureInfo",
      SERVICE: "WMS",
      STYLES: "",
      VERSION: "1.3.0",
      WIDTH,
      TIME: "2023-01-01T00:00:00.000Z",
      FEATURE_COUNT: 1,
      VIEWPARAMS: `country:${queryParameters.country};`,
    });

    const response = await api.get<GetFeatureInfoResponse>(
      `/NextGenDrought/wms?${queryParametersFeature}`,
      {
        baseURL: `${baseURL}/geoserver/`,
      }
    );

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
