import { getAxiosError } from "../helpers/src";

import axios from "axios";
import { getBackendURL } from "./backendURL";

export const getAreas = async (country: string) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    let getAreasByCountryURL = `${baseURL}/features/subareas_by_country`;
    if (country !== "ALL") {
      getAreasByCountryURL = `${getAreasByCountryURL}?countrycode=${country}`;
    } else {
      getAreasByCountryURL = getAreasByCountryURL;
    }

    const response = await axios.get(getAreasByCountryURL);

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
