import {eachYearOfInterval, format} from 'date-fns'
import {Box, IconButton, Slider, Typography} from '@mui/material'
import {FastRewindOutlined, FastForwardOutlined} from '@mui/icons-material'

import {SliderViewProps} from './types'
import useQueryParameters from '../../../../hooks/useQueryParameters'
import {useState, useEffect} from 'react'
import useTimeseriesDates from '../../../../hooks/useTimeseriesStartEnd'
import {getDecadeNumber, getMonthNumber} from '../../../../hooks/useLayers'
import {useUpdateEffect} from 'react-use'

const getYearsArray = (startYear: number, endYear: number) => {
  const startDate = new Date(startYear, 0)
  const endDate = new Date(endYear, 0)

  return eachYearOfInterval({start: startDate, end: endDate})
    .map((year) => format(year, 'yyyy'))
    .reverse()
}
const getDateOfYear = (date: Date | string, year: number) => {
  return new Date(new Date(date).setUTCFullYear(year));
}

const SliderView = ({layerId, layerTime}: SliderViewProps) => {
  const {defaultDate = new Date(), year: yearFromQuery} = layerTime
  const {queryParameters, updateAllLayerDate, updateLayerDate} = useQueryParameters()

  const layer = queryParameters.layers.find((layer) => layer.id === layerId)

  const monthFrom = getMonthNumber(queryParameters.insuranceWindowMonth)
  const dekadFrom = getDecadeNumber(queryParameters.insuranceWindowDekad)
  const monthTo = getMonthNumber(queryParameters.insuranceWindowMonthTo)
  const dekadTo = getDecadeNumber(queryParameters.insuranceWindowDekadTo)

  const dates = useTimeseriesDates(
    monthFrom,
    dekadFrom,
    monthTo,
    dekadTo,
    layer?.timeseriesGroupId
  )

  const [years, setYears] = useState(
    getYearsArray(dates.data?.startYear ?? 2000, dates.data?.endYear ?? 2023)
  )
  const [selectedYear, setSelectedYear] = useState(
    Number(yearFromQuery ?? years[0])
  )

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setSelectedYear(newValue)
    }

    updateLayerDate({id: layerId, year: newValue.toString()})
  }

  const selectYear = (type: string) => {
    const addedYear = type === 'next' ? 1 : -1

    const updatedYear = Number(selectedYear) + addedYear
    setSelectedYear(updatedYear)
    updateLayerDate({id: layerId, year: updatedYear.toString()})
  }

  useUpdateEffect(() => {
    const defaultDateInYear = getDateOfYear(
      defaultDate,
      selectedYear
    )

    if (window.location.pathname.includes('drought-indices')) {
      updateAllLayerDate(defaultDateInYear)
    } else {
      updateLayerDate({id: layerId, date: defaultDateInYear})
    }
  }, [selectedYear])

  useEffect(() => {
    if (window.location.pathname.includes('drought-indices')) {
      setYears(getYearsArray(2000, 2024))
    } else {
      setYears(
        getYearsArray(
          dates.data?.startYear ?? 2000,
          dates.data?.endYear ?? 2024
        )
      )
    }
  }, [window.location.pathname.includes('drought-indices')])

  useEffect(() => {
    setYears(
      getYearsArray(dates.data?.startYear ?? 2000, dates.data?.endYear ?? 2023)
    )
  }, [layerId, dates.data?.startYear, dates.data?.endYear])

  if (!years.length) return null

  return (
    <Box
      sx={{
        width: '780px',
        height: '75px',
        borderRadius: '8px',
        zIndex: 2000,
        position: 'relative',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'flex-end',
        gap: '10px',
        paddingX: '10px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Typography fontSize="12px">Year slider</Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={() => selectYear('previous')}
            disabled={Number(selectedYear) === Number(years[years.length - 1])}
          >
            <FastRewindOutlined fontSize="small" />
          </IconButton>
          <IconButton
            onClick={() => selectYear('next')}
            disabled={Number(selectedYear) === Number(years[0])}
          >
            <FastForwardOutlined fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          paddingRight: '12px',
        }}
      >
        <Slider
          sx={{
            width: '680px',
            scrollbarColor: 'white',
            backgroundColor: 'white',
            backgroundSize: 'auto',
            '& .MuiSlider-markLabel': {
              fontSize: '10px',
            },
          }}
          value={Number(selectedYear)}
          onChange={handleChange}
          valueLabelDisplay="auto"
          step={1}
          marks={years.map((year) => ({
            value: Number(year),
            label: year.toString(),
          }))}
          min={Number(years[years.length - 1])}
          max={Number(years[0])}
        />
      </Box>
    </Box>
  )
}

export default SliderView
