import { Box, Dialog, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

const DialogInfo = ({item, changeItem} : {item: string, changeItem: Dispatch<SetStateAction<string>>}) => {
    const content:any = {
      climate: {
        title: 'Climate data',
        description: 'Climate and vegetation datasets are used to compute the Drought Indices. The data can be visualised on the map (right) and as time series (after a region is selected on the map).​​'
      },
      'Precipitation': {
        title: 'Precipitation',
        description: "The CHIRPS rainfall datasets combines satellite data and weather stations. This dashboard relies on a precipitation product that merges the CHIRPS final and preliminary ('prelim') product. All data were resampled to 10 daily time steps and averaged over each geographical unit.​​"
      },
      'Soil Moisture': {
        title: 'Soil moisture',
        description: 'Root-zone soil moisture is represented by the Copernicus Soil Water Index (SWI). Data is available at 10 daily time step and averaged over each geographical unit.​​'
      },
      'Vegetation': {
        title: 'Vegetation',
        description: 'The vegetation health dataset, also known as Normalized Difference Vegetation Index (NDVI) is a merged product based on data from eVIIRS (from 2007) and MODIS (until 2007). Data has been resampled to 10 daily time steps and averaged over each'
      },
      droughtIndices: {
        title: 'Drought indices',
        description: 'We rely on the standardized anomalies (Z-scores) for all climate and vegetation variables.​ This index indicates how much a given value differs from the standard deviation. The anomaly is computed for the user-defined ‘critical window’ from climate and vegetation data, averaged over the geographical unit.​'
      },
      'Precipitation Anomaly': {
        title: 'Precipitation anomaly',
        description: 'We rely on the standardized anomalies (Z-scores) for all climate and vegetation variables.​ This index indicates how much a given value differs from the standard deviation. The anomaly is computed for the user-defined ‘critical window’ from climate and vegetation data, averaged over the geographical unit.​'
      },
      'Soil Moisture Anomaly': {
        title: 'Soil moisture anomaly',
        description: 'We rely on the standardized anomalies (Z-scores) for all climate and vegetation variables.​ This index indicates how much a given value differs from the standard deviation. The anomaly is computed for the user-defined ‘critical window’ from climate and vegetation data, averaged over the geographical unit.​'
      },
      'Vegetation Anomaly': {
        title: 'Vegetation anomaly',
        description: 'We rely on the standardized anomalies (Z-scores) for all climate and vegetation variables.​ This index indicates how much a given value differs from the standard deviation. The anomaly is computed for the user-defined ‘critical window’ from climate and vegetation data, averaged over the geographical unit.​'
      },
      droughtEvents: {
        title: 'Drought events',
        description: 'We use datasets to represent drought impacts. These datasets can on the time series plots be overlaid with climate data and drought indices.'
      },
      droughtEventsEMDAT: {
        title: 'Drought events from EM-DAT',
        description: 'This dataset corresponds to drought statistics from the International Disasters Database available at the level the Admin 2 level.'
      },
      IPCScore: {
        title: 'IPC Score',
        description: 'Drought statistics from the Integrated Food Security Phase Classification (IPC) scale for Acute Food Insecurity. IPC scores are generated at the level of FEWS NET livelihood zones (higher resolution than Admin 2), but for each area, we use the maximum, annual IPC value for the chosen Admin level.​'
      },
      typeOfComparison: {
        title: 'Type of comparison',
        description: <span>The payout can be computed and compared for different drought indices or different geographical units.​
        <br/>Depending on the selection, the graph below will show simulated historical payouts.</span>
      },
      compareDiffrentDroughtIndices: {
        title: 'Compare different drought indices',
        description: 'You can select multiple drought indices to compare historical payouts for the chosen geographical unit.'
      },
      compareDiffrentGeographicalUnits: {
        title: 'Compare different geographical units',
        description: 'You can select multiple geographical units (up to 3) and one drought index to compare simulated historical payouts.​'
      },
      payoutParameters: {
        title: 'Payout parameters',
        description: 'You can define the parameters used for computing the payout. ​The trigger and Exit levels will be used to determine the conditions when the payout starts/maximum payout is reached. The Sum insured is given in USD, while the other parameters (Deductible, Minimum payout and Maximum payout) are given in percentage of Sum insured.'
      },
    }
  
    const [isOpen, setIsOpen] = useState<boolean>(false)
    
    const handleClose = () => {
      setIsOpen(false)
    }

    useEffect(() => {
      if(item) setIsOpen(true)
    }, [item])

    useEffect(() => {
      if(!isOpen) changeItem('')
    }, [isOpen])

    return (
    <Dialog 
      maxWidth="xs" 
      onClose={handleClose} 
      open={isOpen}
    >
        <Box
            sx={{
                padding: "25px"
            }}
        >
        <Typography fontSize="16px" fontWeight={700} marginBottom="5px">{content[item]?.title}</Typography>
        <Typography>{content[item]?.description}</Typography>
      </Box>
    </Dialog>
    )
}

  export default DialogInfo;