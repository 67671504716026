import { GetTimeseriesRequest } from "../types/timeseries";


import { useQuery } from "react-query";
import { getFeaturesByCountryAdminLevelOne } from "../services/getFeaturesByCountryAdminLevelOne";
import useBboxArea from "./useBboxArea";

export type UseTimeseries = GetTimeseriesRequest;

const useAdminOne = (country: string) => {
  useBboxArea()

  return useQuery(["adminone", country], () => getFeaturesByCountryAdminLevelOne(country), {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useAdminOne;
