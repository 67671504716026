import { getAxiosError } from "../helpers/src";

import extractQueryId from "../helpers/extractQueryId";

import axios from "axios";
import { getBackendURL } from "./backendURL";

export const getIpcScoreFeatureAndGroup = async ({
  queryId,
}: {
  queryId?: string;
}) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    const { featureId } = extractQueryId(queryId ?? "");

    const response = await axios.get(
      `${baseURL}/timeseries/timeseries_by_featureid_from_group?feature_id=${featureId}&group_id=2aa776b3-0c88-41cf-82fb-e033585c8038`
    );

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
