import Box from "@mui/material/Box";

import {
  Dialog,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import useQueryParameters from "../../hooks/useQueryParameters";

import React, { useState } from "react";
import { TARGET_COUNTRY } from "../../enums/targetCountry";
import { MAP_SHIFT_CONSTANT } from "../../enums/map";
import useCountriesDefaultDekad from "../../hooks/useCountriesDefaultDekad";

import seasonalCalendarEthiopia from "../../assets/crop-calendar/seasonal-calendar-ethiopia.png"
import seasonalCalendarKenya from "../../assets/crop-calendar/seasonal-calendar-kenya.png"
import seasonalCalendarSomalia from "../../assets/crop-calendar/seasonal-calendar-somalia.png"

interface SeasonalCalendar {
  [country: string]: string;
}

const seasonalCalendar:SeasonalCalendar = {
  KEN: seasonalCalendarKenya,
  SOM: seasonalCalendarSomalia,
  ETH: seasonalCalendarEthiopia,
}

export const CountrySelection = ({
  titleText,
  description,
  height,
  width,
  textSize,
  showProgress = false,
}: SelectionProps) => {
  const { queryParameters, setQueryParameters } = useQueryParameters();
  useCountriesDefaultDekad()

  const handleChangeCountry = (event: SelectChangeEvent) => {
    const { latitude, longitude, zoom } = TARGET_COUNTRY[event.target.value];
    const isDroughtRiskStep = window.location.pathname.includes('drought-risk')

    setQueryParameters({
      country: event.target.value,
      geographicalUnit: '',
      timeseries: 'hide',
      latitude, 
      longitude: isDroughtRiskStep ? longitude : longitude + ((queryParameters.layers.length + 1) * (window.innerWidth/MAP_SHIFT_CONSTANT)),
      zoom: isDroughtRiskStep ? zoom : 4.5,
    });
  };

  return (
    <Box
      sx={{
        height: "auto",
      }}
    >
      {showProgress && (
        <Box
          paddingBottom="100px"
          paddingTop="25px"
          width="80%"
          display="flex"
          alignItems="center"
        >
          <Box width="100%" mr={1}>
            <LinearProgress 
              variant="determinate" 
              value={25} 
              sx={{ 
                '& .MuiLinearProgress-bar': {
                  backgroundColor: '#2146A4',
                } 
              }}
            />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              25%
            </Typography>
          </Box>
        </Box>
      )}
      {titleText && <Typography fontSize={textSize} color="#2D3648">{titleText}</Typography>}
      {description && <Typography fontSize="16px" paddingBottom={"20px"}>
        {description}
      </Typography>}

      <FormControl fullWidth margin="normal">
        <InputLabel
          sx={{
            background: "white",
            paddingX: '8px'
          }}
        >
          Target country
        </InputLabel>
        <Select
          sx={{ width: width, height: height, color: "black" }}
          defaultValue={queryParameters.country}
          value={queryParameters.country}
          label="Country"
          onChange={handleChangeCountry}
        >
          {Object.keys(TARGET_COUNTRY).map((countryKey) => (
            <MenuItem value={countryKey} key={countryKey}>
              {TARGET_COUNTRY[countryKey].label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export const InsuranceWindowSelection = ({
  titleText,
  description,
  height,
  width,
  textSize,
  paddingTop,
  showProgress,
}: SelectionProps) => {
  const { queryParameters, setQueryParameters } = useQueryParameters();
  const [isOpenCropCalendar, setIsOpenCropCalendar] = useState(false)

  const handleChangeInsuranceWindowDekad = (event: SelectChangeEvent) => {
    setQueryParameters({ insuranceWindowDekad: event.target.value });
  };
  const handleChangeInsuranceWindowMonth = (event: SelectChangeEvent) => {
    setQueryParameters({ insuranceWindowMonth: event.target.value });
  };
  const handleChangeInsuranceWindowDekadTo = (event: SelectChangeEvent) => {
    setQueryParameters({ insuranceWindowDekadTo: event.target.value });
  };
  const handleChangeInsuranceWindowMonthTo = (event: SelectChangeEvent) => {
    setQueryParameters({ insuranceWindowMonthTo: event.target.value });
  };

  return (
    <Box>
      {showProgress && (
      <Box
        sx={{
          height: "auto",
          marginBottom: '30px'
        }}
      >
          <Box
            paddingBottom="100px"
            width="80%"
            paddingTop="25px"
            display="flex"
            alignItems="center"
          >
            <Box width="100%" mr={1}>
              <LinearProgress 
                variant="determinate" 
                value={75} 
                sx={{ 
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#2146A4',
                  } 
                }}
              />
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">
                75%
              </Typography>
            </Box>
          </Box>
        <Typography fontSize={textSize} color="#2D3648">{titleText}</Typography>
        <Typography fontSize="16px">
          Get a better understanding of the seasons by looking at the crop calendar <span style={{ cursor: 'pointer', color: "blue" }} onClick={() => setIsOpenCropCalendar(true)}>here</span>
        </Typography>
      </Box>
      )}
      <Box
        display="flex"
        paddingBottom="15px"
        gap="10px"
        paddingTop="20px"
      >
        <FormControl>
          <InputLabel sx={{ paddingRight: '8px', backgroundColor: 'white' }}>
            From month
          </InputLabel>
          <Select
            sx={{ width: width, height: height, color: "black" }}
            value={queryParameters.insuranceWindowMonth}
            label="Age"
            onChange={handleChangeInsuranceWindowMonth}
          >
            <MenuItem value={"January"}>January</MenuItem>
            <MenuItem value={"February"}>February</MenuItem>
            <MenuItem value={"March"}>March</MenuItem>
            <MenuItem value={"April"}>April</MenuItem>
            <MenuItem value={"May"}>May</MenuItem>
            <MenuItem value={"June"}>June</MenuItem>
            <MenuItem value={"July"}>July</MenuItem>
            <MenuItem value={"August"}>August</MenuItem>
            <MenuItem value={"September"}>September</MenuItem>
            <MenuItem value={"October"}>October</MenuItem>
            <MenuItem value={"November"}>November</MenuItem>
            <MenuItem value={"December"}>December</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel sx={{ paddingRight: '8px', backgroundColor: 'white' }}>
            From dekad
          </InputLabel>
          <Select
            sx={{ width: width, height: height, color: "black" }}
            value={queryParameters.insuranceWindowDekad}
            label="Age"
            onChange={handleChangeInsuranceWindowDekad}
          >
            <MenuItem value={"one"}>1</MenuItem>
            <MenuItem value={"two"}>2</MenuItem>
            <MenuItem value={"three"}>3</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" gap="10px">
        <FormControl>
          <InputLabel sx={{ paddingRight: '8px', backgroundColor: 'white' }}>
            To month
          </InputLabel>
          <Select
            sx={{ width: width, height: height, color: "black" }}
            value={queryParameters.insuranceWindowMonthTo}
            label="Age"
            onChange={handleChangeInsuranceWindowMonthTo}
          >
            <MenuItem value={"January"}>January</MenuItem>
            <MenuItem value={"February"}>February</MenuItem>
            <MenuItem value={"March"}>March</MenuItem>
            <MenuItem value={"April"}>April</MenuItem>
            <MenuItem value={"May"}>May</MenuItem>
            <MenuItem value={"June"}>June</MenuItem>
            <MenuItem value={"July"}>July</MenuItem>
            <MenuItem value={"August"}>August</MenuItem>
            <MenuItem value={"September"}>September</MenuItem>
            <MenuItem value={"October"}>October</MenuItem>
            <MenuItem value={"November"}>November</MenuItem>
            <MenuItem value={"December"}>December</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel sx={{ paddingRight: '8px', backgroundColor: 'white' }}>
            To dekad
          </InputLabel>
          <Select
            sx={{ width: width, height: height, color: "black" }}
            value={queryParameters.insuranceWindowDekadTo}
            label="Age"
            onChange={handleChangeInsuranceWindowDekadTo}
          >
            <MenuItem value={"one"}>1</MenuItem>
            <MenuItem value={"two"}>2</MenuItem>
            <MenuItem value={"three"}>3</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Dialog 
        onClose={() => setIsOpenCropCalendar(false)} 
        open={isOpenCropCalendar}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth:  queryParameters.country === 'ALL' ? "90vw" : "70vw",  // Set your width here
            },
          },
        }}
      >
        <Box textAlign="center" display="flex" justifyContent="center">
            {
              queryParameters.country === 'ALL' ? 
              (
                <Box display="flex">
                  <Box flex={6} border={1}>
                    <img alt="faqMap" width="100%" src={seasonalCalendarEthiopia} />
                  </Box>
                  <Box flex={5}>
                    <Box border={1}>
                      <img alt="faqMap" width="100%" src={seasonalCalendarKenya} />
                    </Box>
                    <Box border={1}>
                      <img alt="faqMap" width="100%" src={seasonalCalendarSomalia} />
                    </Box>
                  </Box>
                </Box>
              ) : 
              (
                <Box>
                  <img style={{ width: '100%', maxHeight: "90vh" }} alt="faqMap" src={seasonalCalendar[queryParameters.country] ?? null} />
                </Box>
              )
            }
          </Box>
      </Dialog>
    </Box>
  );
};

export const AggregationSelection = ({
  titleText,
  description,
  height,
  width,
  textSize,
  showProgress,
}: SelectionProps) => {
  const { queryParameters, setQueryParameters } = useQueryParameters();

  const handleChangeAgregation = (event: SelectChangeEvent) => {
    setQueryParameters({ agregation: event.target.value, geographicalUnit: '' });
  };

  const text = description?.toString();

  const lines = text?.split("\n");

  return (
    <Box
      sx={{
        height: "auto",
      }}
    >
      {showProgress && (
        <Box
          paddingBottom="100px"
          width="80%"
          paddingTop="25px"
          display="flex"
          alignItems="center"
        >
          <Box width="100%" mr={1}>
            <LinearProgress 
                variant="determinate" 
                value={50} 
                sx={{ 
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: '#2146A4', // Set the progress bar color
                  } 
                }}
              />
          </Box>
          <Box minWidth={35}>
            <Typography variant="body2" color="textSecondary">
              50%
            </Typography>
          </Box>
        </Box>
      )}
      {titleText && <Typography fontSize={textSize} color="#2D3648">{titleText}</Typography>}
        {lines?.map((line, index) => (
        <Typography fontSize="16px" paddingBottom="20px" key={`title-${index}`}>
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        </Typography>
        ))}
      <FormControl fullWidth margin="normal">
        <InputLabel sx={{ paddingX: '8px', background: "white" }}>
          Level of aggregation
        </InputLabel>
        <Select
          sx={{ width: width, height: height, color: "black" }}
          value={queryParameters.agregation}
          onChange={handleChangeAgregation}
        >
          <MenuItem value={"country"}>Country</MenuItem>
          <MenuItem value={"admin-level-1"}>Admin 1 </MenuItem>
          <MenuItem value={"admin-level-2"}>Admin 2 </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

const DashboardSteps = () => {
  const { queryParameters } = useQueryParameters();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
      }}
    >
      {queryParameters.step !== 2 && queryParameters.step !== 3 && (
        <CountrySelection
          titleText="Select the target country"
          description=" This is a helping text to understand the expected selection better."
          width="162px"
          height="48px"
          textSize="20px"
          showProgress={true}
        />
      )}

      {queryParameters.step === 2 && (
        <AggregationSelection
          titleText="Choose the level of spatial aggregation"
          description=" Spatial aggregation is the aggregation of  all data points for a group of resources"
          width="162px"
          height="48px"
          textSize="20px"
          showProgress={true}
        />
      )}
      {queryParameters.step === 3 && (
        <InsuranceWindowSelection
          titleText="Please specify the critical window"
          width="162px"
          height="48px"
          textSize="20px"
          showProgress={true}
        />
      )}
    </Box>
  );
};

export default DashboardSteps;

export interface SelectionProps {
  titleText?: string;
  description?: string;
  height?: string;
  width?: string;
  textSize?: string;
  paddingTop?: string;
  showProgress?: boolean;
}
