import { useQuery } from "react-query";
import { getDroughtEventByFeatureAndGroup } from "../services/droughtEvent";
import { GetTimeseriesRequest } from "../types/timeseries";
import useQueryParameters from "./useQueryParameters";

export type UseTimeseries = GetTimeseriesRequest;

const useDroughtEvent = () => {
  try {
    const { queryParameters: { country, droughtEvents }} = useQueryParameters();
    
    const drought = useQuery(
      ["droughtEvents", country],
      async () =>
        await getDroughtEventByFeatureAndGroup(
          country,
        ),
      {
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: droughtEvents
      }
    );
    return drought?.data;

  } catch (error) {
    console.error("Error in useDroughtEvent:", error);
    throw error;
  }
};

export default useDroughtEvent;
