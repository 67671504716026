const extractQueryId = (queryId: string) => {
    const keys: string[] = queryId.split('.');
  
    const [featureCollectionId, featureId, path] = keys;
  
    return {
      featureCollectionId,
      featureId,
      path,
    };
  };
  
  export default extractQueryId;
  