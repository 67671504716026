import { CountryList } from "./types";

export const TARGET_COUNTRY: CountryList = {
  ALL: {
    label: "All",
    latitude: 5.036010510324147,
    longitude: 53.25159882874658,
    zoom: 4,
    cropCalendarLink: [
      "https://fews.net/sites/default/files/styles/large_width_880/public/2023-03/seasonal-calendar-ethiopia.png",
      "https://fews.net/sites/default/files/styles/large_width_880/public/2023-03/seasonal-calendar-kenya.png",
      "https://fews.net/sites/default/files/styles/large_width_880/public/2023-03/seasonal-calendar-somalia.png"
    ]
  },
  ETH: {
    label: "Ethiopia",
    latitude: 8.929473400357523,
    longitude: 46.47723719954698,
    zoom: 5,
    featureId: 'a0d2e52e-97f9-4893-b8b0-68bd6c6ff161',
    cropCalendarLink: ["https://fews.net/sites/default/files/styles/large_width_880/public/2023-03/seasonal-calendar-ethiopia.png"]
  },
  KEN: {
    label: "Kenya",
    latitude: -0.0648629927229365,
    longitude: 44.67567954686456,
    zoom: 5,
    featureId: '7ad616eb-adf2-4dc4-9217-23340e9cd231',
    cropCalendarLink: ["https://fews.net/sites/default/files/styles/large_width_880/public/2023-03/seasonal-calendar-kenya.png"]
  },
  SOM: {
    label: "Somalia",
    latitude: 4.941597145954475,
    longitude: 52.79204410016732,
    zoom: 5,
    featureId: '4864414b-1c30-41ad-bc32-6eadcc9fb010',
    cropCalendarLink: ["https://fews.net/sites/default/files/styles/large_width_880/public/2023-03/seasonal-calendar-somalia.png"]
  },
};
