import useQueryParameters from "./useQueryParameters";

interface UseLayerTimeProps {
  layerId: string;
}

export interface UseLayerTimeReturn {
  dateMode?: "picker" | "slider";
  date?: string | Date | null;
  year?: string;
  defaultDate?: string;
  startDate?: string;
  endDate?: string;
  timestep: number;
}

const useLayerTime = ({ layerId }: UseLayerTimeProps) => {
  const { queryParameters } = useQueryParameters();
  const { layers } = queryParameters;

  const layerFromQuery = layers.find((layer) => layer.id === layerId);
  const { date, dateMode, year, time } = layerFromQuery || {};

  const {
    default: defaultDate,
    start: startDate,
    end: endDate,
    td: timestep = 86400,
  } = time || {};

  return {
    dateMode,
    date: date || defaultDate,
    year,
    defaultDate,
    startDate,
    endDate,
    timestep,
  };
};

export default useLayerTime;
