import { Box } from "@mui/material";

import { LayerDateProps } from "./types";

import SliderView from "./partials/SliderView";
import useLayerTime from "../../hooks/useLayerTime";
import useQueryParameters from "../../hooks/useQueryParameters";

const LayerDate = ({ layerId }: LayerDateProps) => {
  const { updateLayerDate } = useQueryParameters();
  const layerTime = useLayerTime({
    layerId,
  });

  const handleDateChange = (newValue: Date | null) => {
    if (newValue && newValue !== null) {
      return updateLayerDate({
        id: layerId,
        date: newValue,
      });
    }
  };

  if (!layerTime?.date) return null

  return (
    <Box>
      <SliderView
        layerId={layerId}
        layerTime={layerTime}
        onDateChange={handleDateChange}
      />
    </Box>
  );
};

export default LayerDate;
