import { useQuery } from "react-query";

import { getTimeseriesByFeature } from "../services/timeseries";

import { GetTimeseriesRequest } from "../types/timeseries";
import useQueryParameters from "./useQueryParameters";
import { getDecadeNumber, getMonthNumber } from "./useLayers";

export type UseTimeseries = GetTimeseriesRequest;

const useTimeseries = () => {
  const { queryParameters: { layers, country, queryId, insuranceWindowDekad, insuranceWindowMonth, insuranceWindowMonthTo, insuranceWindowDekadTo, droughtEvents, ipcScore }, setQueryParameters } = useQueryParameters();
  const isClimateData = layers.some((layer) => layer.id.includes("climate"));

  const timeseries = useQuery(
    ["timeseries", queryId, insuranceWindowDekad, insuranceWindowDekadTo, insuranceWindowMonth, insuranceWindowMonthTo, layers],
    async () =>
      await getTimeseriesByFeature({
        queryId,
        country,
        isClimateData,
        groupId: layers[0].timeseriesGroupId,
        droughtEvents,
        ipcScore,
        monthFrom: getMonthNumber(insuranceWindowMonth),
        monthTo: getMonthNumber(insuranceWindowMonthTo),
        dekadFrom: getDecadeNumber(insuranceWindowDekad),
        dekadTo:  getDecadeNumber(insuranceWindowDekadTo),
      }),
    {
      enabled: true,
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setQueryParameters({ geounit: data[0].geounit })
      }
    }
  );

  return timeseries;
};

export default useTimeseries;
