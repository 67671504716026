import { GetTimeseriesRequest } from "../types/timeseries";

import { getAreas } from "../services/areas";
import { useQuery } from "react-query";
import useBboxSubarea from "./useBboxSubarea";

export type UseTimeseries = GetTimeseriesRequest;

const useArea = (country: string) => {
  useBboxSubarea()

  return useQuery(["areas", country], () => getAreas(country), {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useArea;
