import useTimeseries from "./useTimeseries";
import usePlot from "./usePlot";
import useQueryParameters, { TimeseriesQuery } from "./useQueryParameters";
import useDroughtEvent from "./useDroughtEvents";
import useIPCScore from "./useIPCScore";

const useTimeseriesSimple = ({
  queryId,
}: TimeseriesQuery) => {
  const { queryParameters: { droughtEvents, ipcScore } } = useQueryParameters()
  const { data: timeseriesData, isLoading } = useTimeseries();
  const droughtEventData = useDroughtEvent()
  const ipcScoreData = useIPCScore()

  const plot = usePlot({
    queryId,
    droughtEventData: droughtEvents ? droughtEventData?.[0]?.data ?? [] : [],
    ipcScoreData: ipcScore ? ipcScoreData?.[0]?.data ?? [] : [], 
    data:  timeseriesData ?? [],
  });

  return { ...plot, isLoading: isLoading };
};

export default useTimeseriesSimple;
