import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { ButtonBase, Typography } from "@mui/material";
import { Close, DragIndicator } from "@mui/icons-material";
import useQueryParameters from "../../../../hooks/useQueryParameters";

const TopBar = () => {
  const { queryParameters, setQueryParameters } = useQueryParameters();

  const onCloseTimeseries = () => {
    setQueryParameters({
      queryId: "",
      timeseries: "hide",
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#EEF2F5",
        padding: "0.5rem 1rem",
        cursor: "move",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <DragIndicator />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          variant="overline"
          color="textSecondary"
          sx={{
            fontWeight: 600,
            lineHeight: 1,
          }}
        >
          {queryParameters.geographicalUnit ? `Timeseries - ${queryParameters.geographicalUnit}` : ''}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Stack direction="row" spacing="0.5rem">
          <ButtonBase onClick={onCloseTimeseries}>
            <Close color="primary" />
          </ButtonBase>
        </Stack>
      </Box>
    </Box>
  );
};

export default TopBar;
