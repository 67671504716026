export const isValidNumberInput = (value: string) => {
  return /^(\d+\.?\d*|\.\d+|\d+\.|\.)?$/.test(value);
};

export const isBetweenRange = (value: number, min: number, max: number) => {
  return value >= min && value <= max;
};

export const exitLevelOptions = [
    { value: 0.5, label: "1 in 2 year event" },
    { value: 0.33, label: "1 in 3 year event" },
    { value: 0.25, label: "1 in 4 year event" },
    { value: 0.2, label: "1 in 5 year event" },
    { value: 0.1, label: "1 in 10 year event" },
    { value: 0.05, label: "1 in 20 year event" },
    { value: 0.02, label: "1 in 50 year event" },
  ];
  