import { useEffect, useState } from 'react';
import { Map as CoreMap } from 'react-map-gl';
import { MapboxOverlay, MapboxOverlayProps } from '@deck.gl/mapbox/typed';
import { useControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { MapProps } from './types';
import useQueryParameters from '../../hooks/useQueryParameters';
import { GeoJsonLayer } from '@deck.gl/layers';
import { GetFeatureInfoRequest } from '../../types/featureInfo';
import { get } from "lodash";
import useFeatureInfo from '../../hooks/useFeatureInfo';
import { useAdminOneGeoJSON, useAdminTwoGeoJSON, useCountryGeoJSON } from '../../hooks/useGeoJSON'
import { TARGET_COUNTRY } from '../../enums/targetCountry';

function DeckGLOverlay(props: MapboxOverlayProps & { interleaved?: boolean }) {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps({ ...props, getCursor: () => 'crosshair' });
  return null;
}

const Map = ({
  mapStyle,
  mapboxApiAccessToken,
  layers = [],
  viewportState,
  onViewportChange,
  children,
  width = '100vw',
}: MapProps) => {
  const { queryParameters, setQueryParameters } = useQueryParameters();
  const [features, setFeatures] = useState<GetFeatureInfoRequest[]>([]);
  const getFeatureInfo = useFeatureInfo(queryParameters);
  // const countryGeoJson = useCountryGeoJSON()
  const adminLevelOneGeoJson = useAdminOneGeoJSON()
  const adminLevelTwoGeoJson = useAdminTwoGeoJSON()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (queryParameters.agregation === 'admin-level-1') {
          setFeatures(adminLevelOneGeoJson)
        } else if (queryParameters.agregation === 'admin-level-2') {
          setFeatures(adminLevelTwoGeoJson)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [queryParameters.agregation]); // Run the effect only once on component mount

  const handleLayerClick = (pickingInfo: any) => {
    if (queryParameters.timeseries) setQueryParameters({ timeseries: "hide", geographicalUnit: '' });
    const { x, y, viewport: viewportFromInfo, coordinate = [] } = pickingInfo;

    if (x <= 0 && y <= 0) return;

    const [longitude, latitude] = coordinate;

    if (!longitude && !latitude) return;

    const bbox = viewportFromInfo?.getBounds();

    const newWidth = viewportFromInfo?.width || 500;
    const newHeight = viewportFromInfo?.height || 500;

    if (!bbox) return;
    const id = queryParameters.layers[0].id
    getFeatureInfo.mutate(
      {
        layerId: id as string,
        x: Math.round(x),
        y: Math.round(y),
        bbox,
        width: newWidth,
        height: newHeight,
        queryParameters,
      },
      {
        onSuccess: ({ features = [] }) => {
          const queryId = get(features, "0.properties.query_id", undefined);
          const nameFeature = get(
            features,
            "0.properties.feature_name",
            undefined
          );

          setQueryParameters({
            queryId: queryId ?? "",
            geographicalUnit: nameFeature,
            timeseries: "show",
          });
        },
      }
    );
  };

  const geoJsonLayer = queryParameters.geographicalUnit
    ? new GeoJsonLayer({
        id: 'geojson-layer',
        data: queryParameters.geoJSONobject?.features,
        pickable: true,
        stroked: true,
        filled: true,
        lineWidthMinPixels: 3,
        getFillColor: [0, 0, 0, 0],
        getLineColor: [255, 0, 0, 255],
      })
    : null;

  const hoverArea = new GeoJsonLayer({
    id: 'hover-layer',
    data: features,
    pickable: true,
    stroked: true,
    filled: true,
    lineWidthMinPixels: 3,
    getLineColor: [0, 0, 0, 0],
    getFillColor: [255, 0, 0, 0],
    onClick: handleLayerClick,
  });

  const allLayers = [geoJsonLayer, ...layers, hoverArea].filter(Boolean);

  return (
    <CoreMap
      id="mainMap"
      {...viewportState}
      mapStyle={mapStyle}
      mapboxAccessToken={mapboxApiAccessToken}
      cursor="crosshair"
      onMove={(evt) => onViewportChange(evt.viewState)}
      style={{ width, height: '100%' }}
    >
      {children}
      <DeckGLOverlay layers={allLayers} interleaved={true} getTooltip={(polygon: any) => {
        const featureName =  polygon?.object?.properties?.name?.split('.')
        if (queryParameters.country === polygon?.object?.properties?.gid_0 || queryParameters.country === "ALL") {
          if (queryParameters.agregation === 'country') return TARGET_COUNTRY[queryParameters.country]?.label
          return queryParameters.agregation === 'admin-level-1' ? featureName?.[1] : featureName?.[2]
        }
        return false
        }} 
      />
    </CoreMap>
  );
};

export default Map;