import { Box } from "@mui/material";

import { TimeseriesItemProps } from "./types";
import Timeseries from "./partials/Timeseries";

const TimeseriesItem = ({ queryId }: TimeseriesItemProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        backgroundColor: "#ffffff",
      }}
    >
      <Box
        flex={1}
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        <Timeseries queryId={queryId}  />
      </Box>
    </Box>
  );
};

export default TimeseriesItem;
