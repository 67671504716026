import { useQuery } from "react-query";
import { getFeatureBboxArea } from "../services/featureBbox";
import useQueryParameters from "./useQueryParameters";

const useBboxArea = () => {
  const { queryParameters: { geographicalUnit, queryId, agregation }, setQueryParameters } = useQueryParameters();
  
  const featureId = queryId?.split('.')?.[1] ?? ''

  return useQuery(["featureInfoBbox", geographicalUnit, queryId], () => getFeatureBboxArea(featureId), {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!queryId && agregation === 'admin-level-1',
    onSuccess: (data)=> {
      setQueryParameters({ geoJSONobject: {
        features: data?.featurecollection?.features ?? ''}
      })
    },
  });
};

export default useBboxArea;
