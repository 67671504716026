import {  useEffect, useState } from "react";

import { Box, IconButton, Typography, Paper, Divider } from "@mui/material";
import { DragIndicator, Close } from "@mui/icons-material";
import Legends from "../Legends";
import useQueryParameters from "../../hooks/useQueryParameters";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

const Details = ({ title, layerId }: { title?: string, layerId: string }) => {
  const [position, setPosition] = useState<
    | {
        x: number;
        y: number;
      }
    | undefined
  >(undefined);

  const [isDraggable, setIsDraggable] = useState(false);
  const { queryParameters } = useQueryParameters();
  const { layers } = queryParameters;

  const withLayers = !!layers.length;

  const handleDragEnabling = () => {
    setIsDraggable(true);
  };

  const handleOnDrag = (e: DraggableEvent, { x, y }: DraggableData) => {
    setPosition({ x, y });
  };

  const [showLegend, setShowLegend] = useState(true)

  const closeLegend = () => {
    setShowLegend(false)
  }

  useEffect(() => {
    setShowLegend(true)
  }, [layers.length])

  if (!withLayers || !showLegend) return null;

  return (
    <Draggable
      key="details"
      disabled={false}
      bounds="parent"
      position={position}
      onDrag={handleOnDrag}
    >
      <Box
        onMouseEnter={handleDragEnabling}
        sx={{
          position: "absolute",
          top: "50px",
          left: "40px",
          zIndex: 1,
          pointerEvents: "none",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "75vh",
            width: "232px",
            backgroundColor: "white",
            pointerEvents: "auto",
            overflow: "hidden",
            borderRadius: "5%",
          }}
        >
          <Box
            onMouseEnter={handleDragEnabling}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0.5rem",
              cursor: "move",
            }}
          >
            <DragIndicator />
            <Typography
              variant="overline"
              color="textSecondary"
              align="center"
              sx={{
                fontWeight: 600,
                lineHeight: 1,
              }}
            >
              {title}
            </Typography>
          <IconButton
            onClick={closeLegend}
            size="small"
          >
            <Close />
          </IconButton>
          </Box>
          <Divider />
          <Box sx={{
            pointerEvents: 'none',
          }}>
            <Legends layerId={layerId}/>
          </Box>
        </Paper>
      </Box>
    </Draggable>
  );
};

export default Details;
