import Draggable from 'react-draggable';
import { Box, Paper, Stack } from '@mui/material';
import TimeseriesItem from './partials/TimeseriesItem';
import TopBar from './partials/TopBar';
import useQueryParameters from '../../hooks/useQueryParameters';

export function TimeseriesChart() {
  const { queryParameters } = useQueryParameters();
  const { queryId, layers, timeseries } = queryParameters;

  if (layers.length === 0 || timeseries === 'hide') return null;

  return (
    <Draggable
      key="timeseries"
      bounds="parent"
    >
      <Box
        sx={{
          position: "absolute",
          bottom: "9rem",
          left: "100px",
          zIndex: 3,
          pointerEvents: "none",
          border: "1px solid black",
        }}
      >
        <Paper
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "663px",
            height: "292px",
            backgroundColor: "white",
            pointerEvents: "auto",
            overflow: "hidden",
            borderRadius: "0rem",
            boxSizing: "content-box",
            resize: "both"
          }}
        >
        <Box>
          <TopBar />
        </Box>
          <Box
            sx={{
              backgroundColor: "#f7f7f7",
              height: "100%",
            }}
            onMouseDown={(e) => e.stopPropagation()}
          >
            <Stack
              direction="row"
              spacing="1rem"
              sx={{
                width: "100%",
                height: "100%",
                overflowX: "auto",
              }}
            >
              <TimeseriesItem
                queryId={queryId ?? ''}
              />
            </Stack>
          </Box>
        </Paper>
      </Box>
    </Draggable>
  );
}

export default TimeseriesChart;
