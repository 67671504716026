import { useState, useCallback, useEffect } from "react";
import { useDebounce, useUpdateEffect } from "react-use";
import { WebMercatorViewport, FlyToInterpolator } from "@deck.gl/core/typed";

import useQueryParameters from "./useQueryParameters";
import { BoundingBox } from "../types/config";

import { ViewportStateProps } from "../types";

const EPSILON = 0.0001;

const areCoordinatesApproxEqual = (
  coord1: number,
  coord2: number,
  epsilon: number = EPSILON
): boolean => {
  return Math.abs(coord1 - coord2) < epsilon;
};

const useMap = () => {
  const defaultBbox = {
    minx: 33.18912888,
    miny: -4.67458296,
    maxx: 51.41541671752941,
    maxy: 14.8454771,
  } as BoundingBox;

  const hornOfAfricaCoordinates = {
    latitude: 7.36060357,
    longitude: 42.302272799999996,
  };

  const { queryParameters, setQueryParameters } = useQueryParameters();
  const [isLoaded, setIsLoaded] = useState(false);

  // const { bbox = defaultBbox } = data || {};

  const [viewportState, setViewportState] = useState<ViewportStateProps>({
    latitude: queryParameters.latitude || hornOfAfricaCoordinates.latitude,
    longitude: queryParameters.longitude || hornOfAfricaCoordinates.longitude,
    zoom: queryParameters.zoom,
    transitionDuration: 500,
    transitionInterpolator: new FlyToInterpolator(),
    dragRotate: false,
    dragRotatePitch: false,
  });

  const onViewportChange = useCallback((viewport: ViewportStateProps) => {
    setViewportState(viewport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onResize = useCallback(
    (newWidth: number) => {
      setViewportState({ ...viewportState, width: newWidth });
    },
    [viewportState]
  );

  const onDeckLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  useUpdateEffect(() => {
    setViewportState((state) => {
      const stateToSet = new WebMercatorViewport({
        ...viewportState,
      }).fitBounds(
        [
          [defaultBbox.minx, defaultBbox.miny],
          [defaultBbox.maxx, defaultBbox.maxy],
        ],
        {
          padding: 0,
        }
      );
      return {
        transitionDuration: 1000,
        transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
        latitude: stateToSet.latitude,
        longitude: stateToSet.longitude,
        zoom: stateToSet.zoom,
        dragRotate: false,
        dragRotatePitch: false,
      };
    });
  }, []);

  const [, cancel] = useDebounce(
    () => {
      if (
        !areCoordinatesApproxEqual(
          viewportState.latitude,
          queryParameters.latitude
        ) ||
        !areCoordinatesApproxEqual(
          viewportState.longitude,
          queryParameters.longitude
        ) ||
        queryParameters.zoom !== viewportState.zoom
      ) {
        setQueryParameters({
          latitude: viewportState.latitude,
          longitude: viewportState.longitude,
          zoom: viewportState.zoom,
        });
      }
    },
    300,
    [viewportState.latitude, viewportState.longitude, viewportState.zoom]
  );

  useUpdateEffect(() => {
    setViewportState((state) => {
      return {
        transitionDuration: 100,
        transitionInterpolator: new FlyToInterpolator({ speed: 0.5 }),
        latitude: queryParameters.latitude,
        longitude: queryParameters.longitude,
        zoom: queryParameters.zoom,
        dragRotate: false,
        dragRotatePitch: false,
      };
    });
  }, [queryParameters.zoom, queryParameters.latitude, queryParameters.longitude]);

  useEffect(() => {
    return cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { viewportState, onViewportChange, onDeckLoad, onResize, isLoaded };
};

export default useMap;
