import { Box, Button, Divider } from "@mui/material";

import { FooterProps } from "./types";

const Footer = ({
  next,
  back,
  nextTitle,
  backTitle,
  disableNext = false,
  disableBack = false,
}: FooterProps) => {
  return (
    <Box
      sx={{
        bottom: 0,
        backgroundColor: "white",
      }}
    >
      <Divider />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: backTitle && nextTitle ? "space-around" : "center",
          paddingY: "15px",
        }}
      >
        {backTitle && (
          <Button
            variant="outlined"
            onClick={back}
            disabled={disableBack}
            sx={{ borderColor: "#2D3648", textTransform: "none", color:"#2D3648" }}
          >
            {backTitle}
          </Button>
        )}
        {nextTitle && (
          <Button
            variant="contained"
            color="primary"
            onClick={next}
            disabled={
              typeof disableNext === "boolean" ? disableNext : disableNext()
            }
            sx={{ backgroundColor: "#2D3648", textTransform: "none" }}
          >
            {nextTitle}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Footer;
