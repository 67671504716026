import { Box, Stack, Typography } from '@mui/material';

import { RasterProps } from './types';

const Raster = ({ colormap }: RasterProps) => {
  const { entries = [] } = colormap || {};

  return (
    <Stack
      direction="row"
      spacing="0.5rem"
      alignItems="flex-start"
      sx={{
        width: '100%',
        flex: 1,
      }}
    >
      {entries.map(({ color, opacity, label }, entryIndex) => (
        <Box
          key={entryIndex}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            sx={{
              height: '1.25rem',
              ...(opacity === '0.0' && {
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'rgba(0, 0, 0, 0.1)',
              }),
            }}
          >
            <Box
              style={{
                backgroundColor: color,
                opacity,
                width: '100%',
                height: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'rgba(0, 0, 0, 0.1)',
              }}
            />
          </Box>
          {label && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                height: '1.5rem',
              }}
            >
              <Typography
                variant="overline"
                align="center"
                sx={{
                  position: 'absolute',
                  fontSize:'0.68rem',
                  lineHeight: 1,
                  width: '100%',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              >
                {label}
              </Typography>
            </Box>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export default Raster;
