const MIKE_COLORS = {
  BRANDBLUE_DEFAULT: "#0B4566",
  BRANDBLUE_DARK: "#09334B",
  BRANDBLUE_LIGHT: "#93C4D4",

  ACTIONBLUE_DEFAULT: "#00A4EC",
  ACTIONBLUE_DARK: "#008BEC",
  ACTIONBLUE_LIGHT: "#97DBF9",

  GREEN_DEFAULT: "#61C051",
  GREEN_DARK: "#3EB22A",
  GREEN_LIGHT: "#BFE7B7",

  PINK_DEFAULT: "#FD3F75",
  PINK_DARK: "#D40D57",
  PINK_LIGHT: "#FFB1C8",

  DARKGREY_DEFAULT: "#86A2B3",
  DARKGREY_DARK: "#557A8F",
  DARKGREY_LIGHT: "#CFDBE2",

  MEDIUMGREY_DEFAULT: "#DBE4E9",
  MEDIUMGREY_DARK: "#CFDBE2",
  MEDIUMGREY_LIGHT: "#F2F5F7",

  XLIGHTGREY: "#F8F8F8",

  WHITE: "#FFFFFF",
  BLACK: "#000000",
};

const {
  MEDIUMGREY_LIGHT,
  BRANDBLUE_DEFAULT,
  DARKGREY_DEFAULT,
  BRANDBLUE_DARK,
} = MIKE_COLORS;

export default {
  color: [
    "#00A4EC",
    "#90A500",
    "#20CC9A",
    "#6D8390",
    "#10E7DC",
    "#D7D7D7",
    "#1A78E7",
    "#E7D4FC",
    "#AB8BE5",
    "#6046A4",
    "#187206",
    "#2EB67D",
    "#6D8390",
  ],
  textStyle: {
    fontSize: 12,
    lineHeight: 12,
    fontWeight: 400,
    color: DARKGREY_DEFAULT,
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      "Arial",
      "sans-serif",
    ].join(","),
  },
  grid: {
    backgroundColor: MEDIUMGREY_LIGHT,
    containLabel: true,
    show: false,
  },
  backgroundColor: "rgba(0,0,0,0)",
  title: {
    textStyle: {
      color: BRANDBLUE_DARK,
      fontWeight: 700,
      fontSize: 12,
      lineHeight: 16,
      fontFamily: "Roboto",
    },
    subtextStyle: {
      color: BRANDBLUE_DARK,
      fontWeight: 700,
      fontSize: 16,
      lineHeight: 20,
      fontFamily: "Roboto",
    },
  },
  legend: {
    itemGap: 5,
    itemWidth: 10,
    itemHeight: 10,
    left: "center",
    orient: "horizontal",
    selectedMode: true,
    textStyle: {
      color: BRANDBLUE_DEFAULT,
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 16,
    },
    pageTextStyle: { color: DARKGREY_DEFAULT },
  },
  tooltip: {
    show: false,
    confine: true,
    trigger: "axis",
    backgroundColor: "#FFF",
    borderColor: "#C3CBD6",
    borderWidth: 1,
    textStyle: {
      color: BRANDBLUE_DEFAULT,
    },
  },
  toolbox: {
    iconStyle: { borderColor: BRANDBLUE_DARK },
  },
  dataZoom: {
    textStyle: { color: DARKGREY_DEFAULT },
    borderColor: "transparent",
    fillerColor: "transparent",
    moveHandleSize: 0,
    dataBackground: {
      lineStyle: {
        color: "rgb(231,236,240)",
      },
      areaStyle: {
        color: "rgb(231,236,240)",
        opacity: 1,
      },
    },
    selectedDataBackground: {
      lineStyle: {
        color: "rgb(134,162,179)",
      },
      areaStyle: {
        color: "rgb(134,162,179)",
        opacity: 1,
      },
    },
  },
};
