export const climateDataStore = [
  {
    id: "precipitation_climate_data_aggregated",
    timeseriesGroupId: "9a5b70fb-38f8-43d4-88b3-b5d3ecd1cbe1",
    displayName: "Precipitation",
    time: {
      default: "2023-01-01T00:00:00+00:00",
      end: "2023-11-18T00:00:00+00:00",
      start: "2000-06-03T00:00:00+00:00",
      td: 86400,
    },
    isDisabled: false,
    dataunit: 'mm',
    isVisible: false,
  },
  {
    id: "soil_moisture_climate_data_aggregated",
    displayName: "Soil Moisture",
    timeseriesGroupId: "1313a856-2f8b-4f80-b005-8d463b84447b",
    time: {
      default: "2023-01-01T00:00:00+00:00",
      end: "2023-11-18T00:00:00+00:00",
      start: "2000-06-03T00:00:00+00:00",
      td: 86400,
    },
    isDisabled: false,
    dataunit: '%',
    isVisible: false,
  },
  {
    id: "vegetation_climate_data_aggregated",
    timeseriesGroupId: "3ef1227e-22a2-47a2-8106-0d63d07b142c",
    displayName: "Vegetation",
    time: {
      default: "2023-01-01T00:00:00+00:00",
      end: "2023-11-18T00:00:00+00:00",
      start: "2000-06-03T00:00:00+00:00",
      td: 86400,
    },
    dataunit: 'unit',
    isDisabled: false,
    isVisible: false,
  },
];