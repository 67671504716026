import { GetLegendsRequest, GetLegendsResponse } from "../types/legends";

import { getAxiosError } from "../helpers/src";
import axios from "axios";
import { getBackendURL } from "./backendURL";

export const getLegends = async ({
  layerId,
  aggregationLevel,
}: GetLegendsRequest) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.geoserverURL;

  const api = axios.create({
    baseURL: `${baseURL}/geoserver/NextGenDrought/`,
    headers: {
      "Content-type": "application/json",
    },
  });

  try {
    const queryParameters1 = JSON.stringify({
      service: "WMS",
      version: "1.1.0",
      request: "GetLegendGraphic",
      layer: layerId,
      format: "application/json",
    });

    const response = await api.get<GetLegendsResponse>(
      `/wms?service=WMS&version=1.1.0&request=GetLegendGraphic&layer=${
        layerId + `_${aggregationLevel}`
      }&format=application/json`
    );

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
