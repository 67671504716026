import { useEffect } from "react";
import { Link, To, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Step,
  StepContent,
  StepIcon,
  StepLabel,
  Stepper,
  Theme,
  makeStyles,
} from "@material-ui/core";
import { HomeOutlined, InfoOutlined, SummarizeOutlined, SpaceDashboardOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import useQueryParameters from "../../hooks/useQueryParameters";
import React from "react";

const Sidebar = () => {
  const location = useLocation();
  const useStyles = makeStyles((theme: Theme) => ({
    link: {
      textDecoration: "none",
      color: "#2D3648",
      fontSize: "14px",
      padding: "12px 0",
      width: '100%',
    },
    activeLink: {
      backgroundColor: "#e5ebf2",
    },
    hoveredLink: {
      '&:hover': {
        background: '#E5E5E5',
      },
    }
  }));

  const classes = useStyles();

  const isDashboard = location.pathname.startsWith("/dashboard");
  const isActiveLink = (pathname: string) => {
    return location.pathname === pathname;
  };

  const dashboardSteps = [
    {
      label: "NGDI Dashboard",
      link: "/home",
      size: "24px",
    },
    {
      label: "Home",
      link: "/home",
      icon: <HomeOutlined />,
    },
    {
      label: "User Guide",
      link: "/disclaimer",
      icon: <SummarizeOutlined />,
    },
    {
      label: "NGDI Dashboard",
      link: "/dashboard/get-started",
      icon: <SpaceDashboardOutlined />,
      child: <NGDIDashboardStep />,
    },
    {
      label: "About",
      link: "/about",
      icon: <InfoOutlined />,
    },
  ];


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "256px",
        minHeight: "100%",
        borderRight: "1px solid #CFDBE2",
        color: "#F5F8F9",
        bgcolor: "#F5F8F9",
      }}
    >
      {
        dashboardSteps.map((step, index) => {
          return (
            <Box key={`sidebar-step-${index}`}>
              <Box 
              className={
                `${classes.link} 
                ${isActiveLink(step.link) && index !== 0 && classes.activeLink}
                ${index !== 0 && classes.hoveredLink}
              `}
              >
                <Link
                  to={step.link}
                  className={`${classes.link} ${isActiveLink(step.link) && index !== 0 && classes.activeLink} ${index !== 0 && classes.hoveredLink}`}
                  key={`sidebar-link-${index}`}
                >
                  <Box display="flex" paddingLeft="20px" gridGap={10}>
                    {step.icon}
                    <Typography fontSize={step.size ?? '18px'}>{step.label}</Typography>
                  </Box>
                </Link>
              </Box>
              { isDashboard && step.child }
            </Box>
          )
        })
      }
    </Box>
  );
};

const NGDIDashboardStep = () => {
  const [activeStep, setActiveStep] = React.useState<number>(3);
  const location = useLocation();
  const useStyles = makeStyles((theme: Theme) => ({    
    stepIconContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    stepper: {
      "& .MuiStepConnector-vertical": {
        marginLeft: 0,
      },
      "& .MuiStepConnector-line": {
        marginLeft: "calc(50% - 4px)",
        borderColor: "#81819C",
        width: '1px'
      },
    },
    step: {
      "& .MuiStepLabel-vertical": {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px'
      },
    },
    activeStep: {
      "& .MuiStepLabel-vertical": {
        display: 'flex',
        flexDirection: 'column',
        gap: '3px'
      },
      "& .MuiStepLabel-label .MuiTypography-body1": {
        color: "#000",
        fontWeight: "600",
      }
    },
    activeStepLabel: {
      fontWeight: "bold",
      color: "#000",
      textAlign: "center",
    },
    stepLabel: {
      marginTop: theme.spacing(1),
      textAlign: "center",
    },
    stepContent: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      textAlign: "center",
    },
  }));
    
  const classes = useStyles();

  const steps = [
    {
      label: "Get Started",
      description: "Define what data you are especially interested in.",
      link: "/dashboard/get-started",
    },
    {
      label: "Identify Drought Risk Areas",
      description:
        "Explore the drought indices for selected areas and time periods.",
      link: "/dashboard/drought-risk",
    },
    {
      label: "Understand Drought Indices",
      description:
        "Learn about various drought indices and their implications.",
      link: "/dashboard/drought-indices",
    },
    {
      label: "Analyse Financial Indices",
      description:
        "Examine financial indices related to drought impacts and management.",
      link: "/dashboard/financial-indices",
    },
  ]

  const { queryParameters, createQueryParametersString } = useQueryParameters();
  const navigate = useNavigate();

  useEffect(() => {
    const currentStepIndex = steps.findIndex((step) =>
      location.pathname.includes(step.link)
    );
    if (currentStepIndex !== -1) {
      setActiveStep(currentStepIndex);
    }
  }, [location]);

  const handleStepClick = (index: React.SetStateAction<number>, path: To) => {
    setActiveStep(index);
    const queryParameters11 = createQueryParametersString({
      ...queryParameters,
    });

    navigate({
      pathname: `${path}`,
      search: queryParameters11,
    });
  };

  return  (
    <Box>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.stepper}
        style={{ background: "#F5F8F9" }}
      >
        {steps.map((step, index) => (
          <Step
            key={step.label}
            onClick={() => handleStepClick(index, step.link)}
            className={activeStep === index ? classes.activeStep : classes.step}
          >
            <StepLabel
              StepIconComponent={(props) => (
                <Box className={classes.stepIconContainer}>
                  <StepIcon {...props}>
                    <Typography
                      variant="body1"
                      style={{ fontSize: "12px" }}
                    >
                      {index + 1}
                    </Typography>
                  </StepIcon>
                </Box>
              )}
              classes={{
                label: `${classes.stepLabel} ${
                  activeStep === index ? classes.activeStepLabel : ""
                }`,
              }}
              style={{ flexDirection: "column", alignItems: "center", cursor: 'pointer' }}
            >
              <Typography fontSize={"14px"}>{step.label}</Typography>
              <Box className={classes.stepContent} style={{ cursor: 'pointer' }}>
                <Typography fontSize={"12px"}>{step.description}</Typography>
              </Box>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default Sidebar;
