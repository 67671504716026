import Box from "@mui/material/Box";

import { ContentProps } from "../Hero/types";
import { Card, CardContent, Typography } from "@mui/material";

const PageContent = ({ children, title }: ContentProps) => {
  return (
    <Card
      sx={{
        boxShadow: "none",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CardContent>
        <Typography
          variant="h1"
          fontSize="40px"
          lineHeight="24px"
          textAlign="center"
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Box
          paddingTop="30px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {children}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PageContent;
