import { getAxiosError } from "../helpers/src";

import axios from "axios";
import { getBackendURL } from "./backendURL";

export const getFeatureBboxArea = async (featureId: string) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;

  try {
    let featureUrl = `${baseURL}/features/area_with_bbox?areaid=${featureId}`;

    const response = await axios.get(featureUrl);

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};

export const getFeatureBboxSubarea = async (featureId: string) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;

  try {
    let featureUrl = `${baseURL}/features/subarea_with_bbox?subareaid=${featureId}`;

    const response = await axios.get(featureUrl);

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
