import { GetTimeseriesRequest } from "../types/timeseries";
import { getCountries } from "../services/countries";
import { useQuery } from "react-query";

export type UseTimeseries = GetTimeseriesRequest;

const useCountries = () => {
  return useQuery(["countries"], () => getCountries(), {
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};

export default useCountries;
