export const layersData = [
  {
    id: "new_precipitation_layer",
    timeseriesGroupId: "9a5b70fb-38f8-43d4-88b3-b5d3ecd1cbe1",
    displayName: "Precipitation Anomaly",
    time: {
      default: "2023-01-01T00:00:00+00:00",
      end: "2023-11-18T00:00:00+00:00",
      start: "2000-01-01T00:00:00+00:00",
      td: 86400,
    },
    isDisabled: false,
    isVisible: false,
  },
  {
    id: "svi40-aggregated",
    timeseriesGroupId: "1313a856-2f8b-4f80-b005-8d463b84447b",
    displayName: "Soil Moisture Anomaly",
    time: {
      default: "2023-01-01T00:00:00+00:00",
      end: "2023-11-18T00:00:00+00:00",
      start: "2007-01-01T00:00:00+00:00",
      td: 86400,
    },
    isDisabled: false,
    isVisible: false,
  },
  {
    id: "aggregated-test",
    timeseriesGroupId: "3ef1227e-22a2-47a2-8106-0d63d07b142c",
    displayName: "Vegetation Anomaly",
    time: {
      default: "2023-01-01T00:00:00+00:00",
      end: "2023-11-18T00:00:00+00:00",
      start: "2002-01-01T00:00:00+00:00",
      td: 86400,
    },
    isDisabled: false,
    isVisible: false,
  },
];
