import { ReactNode, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { climateDataStore } from "../../helpers/src/ClimateDataStore";
import { layersData } from "../../helpers/src/Layersstore";
import AnomalieFilters from "../Anomalie/AnomalieFilters";
import {
  CountrySelection,
  AggregationSelection,
  InsuranceWindowSelection,
} from "../DashboardSteps/DashboardSteps";
import React from "react";

import useAdminOne from "../../hooks/useAdminOne";
import useArea from "../../hooks/useArea";
import useCountries from "../../hooks/useCountries";
import useQueryParameters from "../../hooks/useQueryParameters";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Card from "./Card";
import { Info } from "@mui/icons-material";
import DialogInfo from "../Dialog/DialogInfo";

interface SidebarProps {
  children: ReactNode;
}

const FiltersSidebar = ({ children }: SidebarProps) => {
  const [targetAreaAndPeriodExpanded, setTargetAreaAndPeriodExpanded] =
    useState(false);
  const [insuranceWindowExpanded, setInsuranceWindowExpanded] = useState(false);
  const [geographicalUnitsExpanded, setGeographicalUnitsExpanded] =
    useState(true);
  const [droughtEventsExpanded, setDroughtEventsExpanded] = useState(true);
  const [droughtIndicesExpanded, setDroughtIndicesExpanded] = useState(true);
  const [climateDataExpanded, setClimateDataExpanded] = useState(true);
  const [dialogContent, setDialogContent] = useState<string>('')

  const { queryParameters, setQueryParameters } =
    useQueryParameters();

  const { data: adminLevelOneData } = useAdminOne(queryParameters?.country);
  const { data: adminLevelTwoData } = useArea(queryParameters?.country);
  const { data: countryData } = useCountries();
  
  let geographicalUnitList: any[] = [];
  if (queryParameters.agregation === "admin-level-1") {
    geographicalUnitList = adminLevelOneData?.sort(
      (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)
      ) ?? [];
  } else if (queryParameters.agregation === "admin-level-2") {
    geographicalUnitList = adminLevelTwoData?.sort(
      (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)
      ) ?? [];
  } else if (queryParameters.agregation === "country") {
    geographicalUnitList = countryData;
  }
      
  const handleTargetAreaAndPeriodChange = () => {
    setTargetAreaAndPeriodExpanded(!targetAreaAndPeriodExpanded);
  };
  
  const handleInsuranceWindowChange = () => {
    setInsuranceWindowExpanded(!insuranceWindowExpanded);
  };

  const handleGeographicalUnitsChange = () => {
    setGeographicalUnitsExpanded(!geographicalUnitsExpanded);
  };

  const handleDroughtEventsChange = () => {
    setDroughtEventsExpanded(!droughtEventsExpanded);
  };

  const handleDroughtIndicesChange = () => {
    setDroughtIndicesExpanded(!droughtIndicesExpanded);
  };
  const handleClimateData = () => {
    setClimateDataExpanded(!climateDataExpanded);
  };

  const handleOpenDialog = (e: React.MouseEvent<HTMLButtonElement>, type: string) => {
    e.stopPropagation()
    setDialogContent(type)
  }

  const isDroughtRiskStep = window.location.pathname.includes("drought-risk");
  const isFinancialIndicesStep =
    window.location.pathname.includes("financial-indices");

  return (
    <Box 
      borderRight="0.01rem solid #dadada"
      sx={{
        overflow: "auto",
        overflowY: "auto",
        overflowX: "hidden",
        height: "100%",
        '&::-webkit-scrollbar': {
          width: '1px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#002244',
        },
      }}
    >
      <AccordionStyled
        expanded={targetAreaAndPeriodExpanded}
        onChange={handleTargetAreaAndPeriodChange}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <Typography>Target Area</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CountrySelection width="269px" height="56px" textSize="10px" />
            <AggregationSelection width="269px" height="56px" textSize="10px" />
          </Box>
        </AccordionDetails>
      </AccordionStyled>

      <AccordionStyled
        expanded={insuranceWindowExpanded}
        onChange={handleInsuranceWindowChange}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Critical Window</Typography>
        </AccordionSummary>
        <AccordionDetails  sx={{ paddingTop: 0 }}>
          <InsuranceWindowSelection
            width="120px"
            height="56px"
            textSize="10px"
          />
        </AccordionDetails>
      </AccordionStyled>

      {!isFinancialIndicesStep && (
        <Box>
          <AccordionStyled
            expanded={geographicalUnitsExpanded}
            onChange={handleGeographicalUnitsChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Geographical Units</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Autocomplete
                sx={{ width: "100%", height: "20px", paddingBottom: "30px" }}
                options={geographicalUnitList? geographicalUnitList:[]}
                getOptionLabel={option => option.name ?? ''}
                isOptionEqualToValue={(option, value) => option.name === value?.name}
                value={{  name: geographicalUnitList ? geographicalUnitList?.find(geoUnit => geoUnit.name === queryParameters.geographicalUnit || geoUnit.id === queryParameters.geographicalUnit)?.name ?? '':"" }}
                onChange={(event: any, newValue: { name: string; id: string; featurecollection: any } | null) => {
                  if (newValue) {
                    setQueryParameters({
                      geographicalUnit: newValue.name,
                      queryId: `${queryParameters.layers[0]?.timeseriesGroupId}.${newValue.id}`,
                      timeseries: "show",
                      ...(queryParameters.agregation === "country" ?
                        { 
                          country: newValue.name.slice(0, 3).toUpperCase(),
                          geoJSONobject: { features: newValue.featurecollection?.features ?? '' }
                        } : {})
                    });
                  } else {
                    setQueryParameters({
                      timeseries: 'hide',
                      geographicalUnit: '',
                      queryId: '',
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Geographical Units"
                    placeholder="Geographical Units"
                  />
                )}
              />
            </AccordionDetails>
          </AccordionStyled>
          {isDroughtRiskStep && <AccordionStyled
            expanded={climateDataExpanded}
            onChange={handleClimateData}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <Box display="flex" gap="5px" alignItems="center">
                <Typography>Climate data</Typography>
                <IconButton size="small" onClick={(e) => handleOpenDialog(e, 'climate')}>
                  <Info fontSize="small"/>
                </IconButton>
              </Box>
            </AccordionSummary>
            <Card layers={climateDataStore} openDialog={handleOpenDialog} />
          </AccordionStyled>}
          <AccordionStyled
            expanded={droughtIndicesExpanded}
            onChange={handleDroughtIndicesChange}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box display="flex" gap="5px" alignItems="center">
                <Typography>Drought indices</Typography>
                <IconButton size="small" onClick={(e) => handleOpenDialog(e, 'droughtIndices')}>
                  <Info fontSize="small"/>
                </IconButton>
              </Box>
            </AccordionSummary>

            <Card layers={layersData} openDialog={handleOpenDialog} />
          </AccordionStyled>
          {isDroughtRiskStep && (
            <AccordionStyled
              expanded={droughtEventsExpanded}
              onChange={handleDroughtEventsChange}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Box display="flex" gap="5px" alignItems="center">
                  <Typography>Drought events</Typography>
                  <IconButton size="small" onClick={(e) => handleOpenDialog(e, 'droughtEvents')}>
                    <Info fontSize="small"/>
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <AnomalieFilters
                  title={"Drought events from EM-DAT"}
                  toggleKey={"droughtEvents"}
                  isChecked={queryParameters.droughtEvents}
                  disabled={queryParameters.ipcScore}
                  openDialog={(e) => handleOpenDialog(e, 'droughtEventsEMDAT')}
                  />
                <Box paddingTop="20px">
                  <AnomalieFilters
                    title={"IPC Score"}
                    toggleKey={"ipcScore"}
                    isChecked={queryParameters.ipcScore}
                    disabled={queryParameters.droughtEvents}
                    openDialog={(e) => handleOpenDialog(e, 'IPCScore')}
                  />
                </Box>
              </AccordionDetails>
            </AccordionStyled>
          )}
        </Box>
      )}
      <DialogInfo item={dialogContent} changeItem={setDialogContent} />
    </Box>
  );
};

export default FiltersSidebar;

const AccordionStyled = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  width: "100%",
  backgroundColor: "white",
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

