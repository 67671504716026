import Layout from "../../Components/Layout";

import DroughtMap from "../../Components/DroughtMap/DroughtMap";

import useArea from "../../hooks/useArea";
import useQueryParameters from "../../hooks/useQueryParameters";

import { useNavigate } from "react-router";
import useAdminOne from "../../hooks/useAdminOne";
import useCountries from "../../hooks/useCountries";
import { layersData } from "../../helpers/src/Layersstore";
import { useEffect } from "react";
import { TARGET_COUNTRY } from "../../enums/targetCountry";
import Carousel from "./partials/Carousel/Carousel";

const DroughtRisk = () => {
  const { queryParameters, createQueryParametersString, setQueryParameters } = useQueryParameters();

  useEffect(() => {
    setQueryParameters({
      layers: [{...layersData[0], ...queryParameters.layers[0]}],
      latitude: TARGET_COUNTRY[queryParameters.country].latitude,
      longitude: TARGET_COUNTRY[queryParameters.country].longitude,
      zoom: 5
    })
  }, [])

  const { data: adminLevelOneData } = useAdminOne(queryParameters?.country);
  const { data: adminLevelTwoData } = useArea(queryParameters?.country);
  const { data: countryData } = useCountries();

  let geographicalUnitList: any[] = [];
  if (queryParameters.agregation === "admin-level-1") {
    geographicalUnitList = adminLevelOneData?.sort(
      (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)
    );
  } else if (queryParameters.agregation === "admin-level-2") {
    geographicalUnitList = adminLevelTwoData?.sort(
      (a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)
    );
  } else if (queryParameters.agregation === "country") {
    geographicalUnitList = countryData;
  }


  const { layers } = queryParameters;

  let optionss = [];
  if (geographicalUnitList) {
    optionss = geographicalUnitList.map((item: { name: any }) => item.name);
  }

  const navigate = useNavigate();
  const handleBack = () => {
    const pathname = "/dashboard/get-started";
    const queryParameters1 = createQueryParametersString({});

    navigate({
      pathname: `${pathname}`,
      search: queryParameters1,
    });
  };

  const handleNext = () => {
    const pathname = "/dashboard/drought-indices";
    const queryParameters1 = createQueryParametersString({});

    navigate({
      pathname: `${pathname}`,
      search: queryParameters1,
    });
  };

  return (
      <Layout
        next={handleNext}
        back={handleBack}
        nextTitle="Next"
        backTitle="Back"
        showFilterSidebar={true}
      >
        <Carousel />
        <DroughtMap
          width={600}
          height={600}
          layerId={layers[layers.length - 1]?.id ?? ""}
          title={layers[layers.length - 1]?.displayName}
          mapTitle={false}
        />
      </Layout>
  );
};

export default DroughtRisk;
