export const getMonthName = (monthNumber: number) => {
    const months = [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];
  
    if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
    } else {
      return '';
    }
}

export const getDekadName = (dekadNumber: number) => {
    const dekads = ['one', 'two', 'three'];
  
    if (dekadNumber >= 1 && dekadNumber <= 3) {
      return dekads[dekadNumber - 1];
    } else {
      return '';
    }
}