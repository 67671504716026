import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { Box, IconButton } from "@mui/material";
import useQueryParameters from "../../hooks/useQueryParameters";
import { ToggleBoxProps } from "../Hero/types";
import { Info } from "@mui/icons-material";

const ToggleBox = ({ title, toggleKey, disabled, isChecked, openDialog }: ToggleBoxProps) => {
  const { queryParameters, setQueryParameters } = useQueryParameters();

  const handleToggleChange = () => {
    switch (toggleKey) {
      case "droughtEvents":
        setQueryParameters({
          ...queryParameters,
          droughtEvents: !queryParameters.droughtEvents,
        });
        break;
      case "ipcScore":
        setQueryParameters({
          ...queryParameters,
          ipcScore: !queryParameters.ipcScore,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: 'center'
      }}
    >
      <Box display="flex" gap="5px" alignItems="center">
        <Typography fontSize="0.9rem">{title}</Typography>
        <IconButton size="small" onClick={openDialog}>
          <Info fontSize="small"/>
        </IconButton>
      </Box>
      <Switch
        onChange={handleToggleChange}
        checked={isChecked}
        disabled={disabled}
        size="small"
      />
    </Box>
  );
};

export default ToggleBox;
