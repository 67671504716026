import { Box, Dialog, IconButton, Typography } from "@mui/material"
import { useState } from "react";
import { ArrowBack, ArrowForward, Circle, Close } from "@mui/icons-material";
import geographicalUnit from '../../../../assets/guide/geographical-unit.png'
import yearSlider from '../../../../assets/guide/year-slider.png'
import timeseriesChart from '../../../../assets/guide/timeseries-chart.png'
import droughtEvent from '../../../../assets/guide/drought-event.png'

const guides = [
    { 
        title: 'Explore the geographical units',
        description: <span>You can choose a geographical unit by either <br />clicking on the map or by searching for it.</span>,
        image: geographicalUnit,
    },
    { 
        title: 'Switch between the years',
        description: <span>You can change the year by using the year <br /> slider, the map will be automatically updated.</span>,
        image: yearSlider,
    },
    { 
        title: 'Display time series',
        description: <span>Click on the map to open the time series for<br/> the selected geographical unit.</span>,
        image: timeseriesChart,
    },
    { 
        title: 'Add drought impacts',
        description: <span>Toggle on the drought events to get drought <br/> impacts information overlaid on the time series.</span>,
        image: droughtEvent,
    },
]

const CarouselGuide = () => {
    const [isOpen, setIsOpen] = useState(true);
    const [step, setStep] = useState(0);

    const hasSeenCarousel = localStorage.getItem('guidance')

    const handleStep = (stage: number) => {
        if ((step + stage) > (guides.length - 1)) return setStep(0);
        if (step + stage < 0 ) return setStep(guides.length - 1);
        setStep(step + stage);
    }
    
    const handleClose = (event: any, reason: string) => {
        if (reason && reason === "backdropClick") return;
        setIsOpen(false)
        localStorage.setItem('guidance', 'shown')
    }

    if (hasSeenCarousel) return null;

    return (
        <Dialog 
            onClose={handleClose} 
            open={isOpen}
            className="dialog-jay"
            maxWidth="md" 
            sx={{      
                '& .MuiPaper-root': {
                  background: 'transparent',
                  boxShadow: 'none',
                  gap: "10px"
                },
                bottom: '20vh'
            }}
        >
            <Box sx={{ position: "relative", right: '30px', top: "30px", zIndex: 1, textAlign: 'right' }}>
                <IconButton onClick={(e) => handleClose(e, 'closeDialog')}>
                    <Close sx={{ background: 'white', borderRadius: '50%' }} />
                </IconButton>
            </Box>
            <Box display="flex" justifyContent="center" position="relative" gap="10px" alignItems="center">
                <Box>
                    <IconButton sx={{ background: '#2D3648', color:"white" }} onClick={() => handleStep(-1)}>
                        <ArrowBack />
                    </IconButton>
                </Box>
                <Box 
                    display="flex" 
                    flexDirection="column" 
                    bgcolor="white" 
                    maxWidth="368px"
                    borderRadius="5px"
                    boxShadow='0px 4px 8px rgba(0, 0, 0, 0.5)'
                >
                    <Box>
                        <img alt="faqMap" style={{ borderRadius: "5px 5px 0 0" }} width="368px" height="200px" src={guides[step].image ?? null} />
                    </Box>
                    <Box textAlign="center" padding="15px">
                        <Typography sx={{ fontWeight: '600'}}>{guides[step].title}</Typography>
                        <Typography sx={{ lineHeight: '0.5px' }} variant="caption">{guides[step].description}</Typography>
                    </Box>
                </Box>
                <Box>
                    <IconButton  sx={{ background: '#2D3648', color:"white" }} onClick={() => handleStep(1)}>
                        <ArrowForward />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" justifyContent="center">
                {guides.map((_, index) => (
                    <IconButton key={index} onClick={() => setStep(index)}>
                        <Circle sx={{ width: "12px", height: "12px", color: step === index ? "#2D3648" : "gray" }} />
                    </IconButton>
                ))}
            </Box>
        </Dialog>
    )
}

export default CarouselGuide;