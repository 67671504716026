import { Box, Stack, Typography } from '@mui/material';

import { PolygonProps } from './types';

const Polygon = ({
  ruleTitle,
  fill,
  'fill-opacity': fillOpacity,
}: PolygonProps) => {
  return (
    <Box>
      <Stack direction="row" spacing="0.5rem" alignItems="center">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: fill,
              width: '1rem',
              height: '1rem',
              opacity: fillOpacity || 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '25%'
            }}
          />
        </Box>
        <Typography variant="body2" fontSize="12px">{ruleTitle}</Typography>
      </Stack>
    </Box>
  );
};

export default Polygon;
