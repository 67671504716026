import { Box, Button, Typography } from "@mui/material";
import Hero from "../../Components/Hero";
import Layout from "../../Components/Layout";
import FAQComponent from "../../Components/faq/FAQ";
import { useNavigate } from "react-router-dom";

const userGuideDocument = require("../../assets/documents/user-guide.pdf")

const DisclaimerAndGuide = () => {
  const navigate = useNavigate();

  return (
    <Layout
      next={() => navigate('/dashboard/get-started')}
      nextTitle="Get started"
      showFilterSidebar={false}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Hero
          headline="User Guide"
          subtitle="FAQ"
          description="We have collected frequently asekd questions, that might help you in getting your question answered. You can also read the full user guide below."
        >
          <Box sx={{ marginTop: "25px" }}>
            <FAQComponent />
          </Box>
          <Box sx={{ paddingY: "40px" }}>
            <Typography variant="h5" fontSize="24px">
              Documentation
            </Typography>
            <Typography fontSize="16px">
              Please download the user guide and the step-by-step use case for Somalia to learn more.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                marginTop: "30px",
                fontSize: "14px",
                borderColor: "#2D3648",
                textTransform: "none",
                color:"#2D3648"
              }}
              onClick={() => window.open(userGuideDocument, '_blank')}
            >
              Read full user guide
            </Button>
          </Box>
        </Hero>
      </Box>
    </Layout>
  );
};

export default DisclaimerAndGuide;
