export const getAnomalyColor = (anomaly: string | string[]) => {
    const colors = ["#00A4EC", "#F06292", "#4CDBCF"];
    if (anomaly?.includes("Vegetation")) {
      return colors[2];
    } else if (anomaly?.includes("Precipitation")) {
      return colors[0];
    } else if (anomaly?.includes("Soil Moisture")) {
      return colors[1];
    } else {
      return "#000";
    }
  };
  