import moment from "moment";
import { getAnomalyColor } from "../helpers/anomaliesHelper";
import { PlotProps } from "../types/echarts";

const flowPlot = ({ series, displayName, unit, ipcScore, droughtEvents, legendQuery, isClimateData }: PlotProps) => {
  const color = series.map(
    (item) => (item.lineStyle.color = getAnomalyColor(item.name))
  );
  const legenddata = series.map(({ name }) => ({ name }));

  const selectedLegend = () => {
    const selected: Record<string, boolean> = {};
    legenddata.forEach(({ name }, index) => selected[name] = (ipcScore || droughtEvents) && index === (legenddata.length - 1) ? true : displayName?.includes(name) ?? false);
    return selected
  }

  const yAxis = [{
    type: "value",
    name: unit ?? 'anomaly',
    nameLocation: 'end',
    alignTicks: false,
  }]

  if (ipcScore || droughtEvents) yAxis.push({
    type: 'value',
    name: '[-]',
    nameLocation: 'end',
    alignTicks: true,
  })

  const option = {
    color,
    legend: {
      data: legenddata,
      selected: { ...selectedLegend(), ...legendQuery},
      padding: 7,
      bottom: 0,
      textStyle: {
        fontSize: "10px",
      },
      itemGap: 20,
    },
    xAxis: {
      type: "time",
      axisLabel: {
        formatter: (value: any) => {
          return moment(value)?.format("YYYY");
        },
      },
    },
    yAxis,
    grid: { top: 70, left: 40, right: 20, bottom: 30 },
    tooltip: {
      show: true,
      trigger: "axis",
      valueFormatter: (value: string | number) =>
        typeof value != "string" ? value.toFixed(2) : value,
      axisPointer: {
        label: {
          formatter: (params: any) => {
            const timestamp = params.value;
            const formattedDate = moment(timestamp).format(isClimateData ? "YYYY-MM-DD" : "YYYY");
            return `${formattedDate}`;
          },
        },
      },
    },
    toolbox: {
      magicType: { show: true, type: ["line", "bar"] },
      feature: {
        dataZoom: {
          yAxisIndex: "none",
        },
        restore: {},
        saveAsImage: {
          pixelRatio: 10,
          backgroundColor: "white",
        },
      },
      top: 75,
      left: "left",
      orient: "vertical",
    },
    dataZoom: [
      {
        type: "slider",
        top: 0,
      },
    ],
  };

  return { series, option };
};

export default flowPlot;
