import { Box, Typography, Stack } from "@mui/material";

import { PointProps } from "./types";

const getTriangleSx = (
  size: string,
  fill: string | undefined,
  fillOpacity: string | undefined
) => ({
  width: `${0}px`,
  height: `${0}px`,
  borderLeft: `${size}px solid transparent`,
  borderRight: `${size}px solid transparent`,
  borderBottom: `${size}px solid ${fill}`,
  opacity: fillOpacity || 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const getCircleorSquareSx = (
  isCircle: boolean,
  size: string,
  fill: string | undefined,
  fillOpacity: string | undefined
) => ({
  backgroundColor: fill,
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: isCircle ? "50%" : "0%",
  opacity: fillOpacity || 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderWidth: "1px",
  borderStyle: "solid",
  borderColor: "rgba(0, 0, 0, 0.1)",
});

const Point = ({ ruleTitle, size, graphics = [] }: PointProps) => {
  const [graphic] = graphics || [{}];
  const { fill, mark, "fill-opacity": fillOpacity } = graphic || {};
  const isCircle = mark === "circle";
  const isTriangle = mark === "triangle";

  return (
    <Box>
      <Stack direction="row" spacing="0.5rem" alignItems="center">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={
              isTriangle
                ? getTriangleSx(size, fill, fillOpacity)
                : getCircleorSquareSx(isCircle, size, fill, fillOpacity)
            }
          />
        </Box>
        <Typography variant="body2" fontSize="0.68rem">
          {ruleTitle}
        </Typography>
      </Stack>
    </Box>
  );
};

export default Point;
