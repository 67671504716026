import { Children, cloneElement, isValidElement } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import DraggableCard from './partials/DraggableCard';

import { DraggableListProps } from './types';

const DraggableList = ({ children, onDrag }: DraggableListProps) => {
  return (
    <DndProvider backend={HTML5Backend} context={window}>
      {Children.map(children, (child, index) => {
        if (!isValidElement(child)) {
          return null;
        }

        const { props } = child;

        const { id } = props;

        return (
          <DraggableCard
            id={id}
            index={index}
            onDrag={onDrag}
            isLastItem={index === Children.count(children) - 1}
          >
            {cloneElement(child)}
          </DraggableCard>
        );
      })}
    </DndProvider>
  );
};

export default DraggableList;
