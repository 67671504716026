import { Box, Button, ButtonGroup, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { SyntheticEvent } from 'react';
import useQueryParameters from '../../../../hooks/useQueryParameters';

const Zoom = () => {
  const { queryParameters, setQueryParameters } = useQueryParameters();

  const { zoom = 2 } = queryParameters;

  const handleZoomIn = (event: SyntheticEvent) => {
    event.stopPropagation();

    setQueryParameters({
      zoom: Math.round(zoom) + 1,
    });
  };

  const handleZoomOut = (event: SyntheticEvent) => {
    event.stopPropagation();

    setQueryParameters({
      zoom: Math.round(zoom) - 1,
    });
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        left: `calc(96vw - ${queryParameters.sidebar ? '561px' : '256px'})`,
        top: "10px",
        zIndex: 9999,
      }}
    >
      <Paper>
        <ButtonGroup orientation="vertical" size="small">
          <Button
            variant="text"
            size="small"
            onClick={handleZoomIn}
            sx={{
              minWidth: 'auto !important',
              width: 'auto !important',
              padding: '0.5rem',
            }}
          >
            <AddIcon fontSize="small" />
          </Button>
          <Button
            variant="text"
            size="small"
            onClick={handleZoomOut}
            sx={{
              minWidth: 'auto !important',
              width: 'auto !important',
              padding: '0.5rem',
            }}
          >
            <RemoveIcon fontSize="small" />
          </Button>
        </ButtonGroup>
      </Paper>
    </Box>
  );
};

export default Zoom;
