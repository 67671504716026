import Chart from "../../../../../Chart/";

import useTimeseriesSimple from "../../../../../../hooks/useTimeseriesSimple";
import useQueryParameters, {
  TimeseriesQuery,
} from "../../../../../../hooks/useQueryParameters";
import { Box, CircularProgress, Typography } from "@material-ui/core";

const Timeseries = ({ queryId, settings }: TimeseriesQuery) => {
  const { series, option, isLoading } = useTimeseriesSimple({
    queryId
  });

  const { setQueryParameters } = useQueryParameters()

  const onLegendSelectChanged = async ({selected}: any) => {
    // setQueryParameters({ legend: {...selected} })
  }

  return isLoading ? 
  <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <CircularProgress /> 
  </Box>: series.length === 0 ?
  <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center">
    <Typography align="center">No Data</Typography>
  </Box> :
  <Chart option={{ ...option, series }} onLegendSelectChanged={onLegendSelectChanged} />;
};

export default Timeseries;
