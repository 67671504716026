import { getAxiosError } from "../helpers/src";

import axios from "axios";
import { getBackendURL } from "./backendURL";

export const timeseriesStartEnd = async (
  monthFrom: number,
  dekadFrom: number,
  monthTo: number,
  dekadTo: number,
  groupId?: string
) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    const response = await axios.post(
      `${baseURL}/timeseries/get_year_range?group_id=${groupId}`,
      {
        startmonth: monthFrom,
        startdekat: dekadFrom,
        endmonth: monthTo,
        enddekat: dekadTo,
      }
    );

    const years = {
      startYear: response.data.startyear,
      endYear: response.data.endyear,
    };
    return years;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
