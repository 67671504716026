import flowPlot from "../utils/flowPlot";

import { GetTimeseriesResponse, TimeseriesDataPoint } from "../types/timeseries";


import { SeriesProps } from "../types/echarts";
import useQueryParameters from "./useQueryParameters";
import { climateDataStore } from "../helpers/src/ClimateDataStore";
import { layersData } from "../helpers/src/Layersstore";

interface UseSeriesProps {
  colors: string[];
  data: GetTimeseriesResponse;
  droughtEventData: TimeseriesDataPoint[];
  ipcScoreData: TimeseriesDataPoint[];
  queryId?: string;
}

const useSeries = (props: UseSeriesProps) => {
  const {
    data,
    droughtEventData,
    ipcScoreData,
    colors,
  } = props;
  const { queryParameters: { layers, droughtEvents, ipcScore, legend } } = useQueryParameters()
  const isClimateData = layers.some((layer) => layer.id.includes("climate"));
  const dataForSeries = isClimateData ? climateDataStore : layersData

  const series: SeriesProps[] = data.map(
    ({ data, displayname, groupid }, i) => ({
      data,
      lineStyle: { width: 1, color: colors[i] },
      symbol: "line",
      showSymbol: false,
      name:
        dataForSeries.find((indice) => indice.timeseriesGroupId === groupid)
          ?.displayName || displayname,
      type: !isClimateData || displayname.includes('Precipitation') ? "bar" : "line",
      seriesLayoutBy: "row",
      smooth: true,
    })
  );

  
  if (droughtEvents && droughtEventData) {
    let markedAreaData: { xAxis: string }[][] = []

    droughtEventData?.forEach((data, index: number) => {
      if (data[1] > 0) markedAreaData.push([{ xAxis: data[0] }, { xAxis: droughtEventData[index + 1]?.[0] }])
    })
    
    const droughtSeries: SeriesProps = {
      name: "Drought event",
      type: "line",
      lineStyle: { opacity: 0, width: 0 },
      data: droughtEventData,
      showSymbol: false,
      markArea: {
        data: markedAreaData,
        itemStyle: {
          color: "#B8EA4E",
        },
      },
    };

    series.push(droughtSeries)
  }

  if (ipcScore && ipcScoreData) {
    const ipcScoreSeries: SeriesProps = {
      name: "IPC Score",
      type: "scatter",
      symbolSize: 5,
      lineStyle: { opacity: 0, width: 0, color: 'red' },
      data: ipcScoreData,
      showSymbol: false,
      yAxisIndex: 1,
      itemStyle: {
        color: 'red'
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
    };

    series.push(ipcScoreSeries);
  }

  const plot = flowPlot({
    series,
    displayName: layers[0].displayName,
    unit: layers[0].dataunit,
    ipcScore,
    droughtEvents,
    isClimateData,
    legendQuery: legend,
  });

  return plot;
};

export default useSeries;
