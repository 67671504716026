import { getAxiosError } from "../helpers/src";
import axios from "axios";
import { TARGET_COUNTRY } from "../enums/targetCountry";
import { getBackendURL } from "./backendURL";

export const getDroughtEventByFeatureAndGroup = async (
  countryFeatureId: string
) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;
  try {
    let queryParameters = `feature_id=${TARGET_COUNTRY[countryFeatureId]?.featureId}&group_id=991bff16-ca75-452b-8996-69b80b61b506`;

    const response = await axios.get(
      `${baseURL}/timeseries/timeseries_by_featureid_from_group?${queryParameters}`
    );

    return response.data;
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
