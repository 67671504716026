import { GetTimeseriesResponse, TimeseriesDataPoint } from "../types/timeseries";
import useSeries from "./useSeries";

interface UseTimeseriesSimple {
  data: GetTimeseriesResponse;
  droughtEventData: TimeseriesDataPoint[];
  ipcScoreData: TimeseriesDataPoint[];
  queryId?: string;
}

const usePlot = (props: UseTimeseriesSimple) => {
  const {
    data,
    droughtEventData,
    ipcScoreData,
    queryId,
  } = props;

  const colors = ["#3fb1e3", "#6be6c1", "#626c91"];

  const plot = useSeries({
    data,
    droughtEventData,
    ipcScoreData,
    colors,
    queryId,
  });

  return plot;
};

export default usePlot;
