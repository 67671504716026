import { Box, LinearProgress, Typography } from "@mui/material";
import { ReactNode } from "react";

import CoreMap from "../Map/Map";
import { token, style } from "./lib/mapbox";
import useMap from "../../hooks/useMap";

import useLayers from "../../hooks/useLayers";
import LayerDate from "../LayerDate/LayerDate";
import Details from "../Details";
import Timeseries from "../TimeseriesChart";
import Zoom from "../Map/partials/Zoom";
import useQueryParameters from "../../hooks/useQueryParameters";


const DroughtMap: React.FC<MapProps> = ({
  children,
  width,
  height,
  details = true,
  layerId,
  title,
  mapTitle = true,
  timeseries = true,
  timeslider = true,
}) => {
  const isDroughtRiskStep = window.location.pathname.includes("drought-risk");
  const { isLoaded, ...viewport } = useMap();
  
  const { layers, isLoading } = useLayers({ width, height });
  const selectedLayer = [layers.find((layer) => layer?.id === layerId)]
  const { queryParameters: { sidebar, layers: allLayers }} = useQueryParameters()

  const layerLength = (isDroughtRiskStep || allLayers.length === 0) ? 1 : allLayers.length;

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        maxWidth: `calc((100vw - ${sidebar ? '560px' : '256px'})/${layerLength})`,
      }}
    >
      <CoreMap
        mapboxApiAccessToken={token}
        mapStyle={style}
        {...viewport}
        layers={
          layerId ? selectedLayer : layers
        }
      >
        {children}
      </CoreMap>
      <Box
        sx={{
          width: "100%",
          position: "absolute",
          display: 'flex',
          justifyContent: 'center',
          zIndex: 2,
          top: "10px",
        }}
      >
        <Typography
          sx={{
            backgroundColor: "white",
            paddingX: "8px",
            pointerEvents: "none",
            textAlign: "center",
            borderRadius: "4px",
          }}
        >
          {mapTitle && title}
        </Typography>
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "60%",
          transform: "translate(10px, -90px)",
          zIndex: 3,
        }}
      >
        {timeslider && <LayerDate layerId={layerId} />}
      </Box>
      <Details title={title} layerId={layerId} />
      {timeseries && <Timeseries />}
      <Zoom />
      { isLoading && <Box  sx={{ position: "absolute", zIndex: 10, top: '0', width: '100%' }}>
        <LinearProgress />
      </Box>}
    </Box>
  );
};

export interface MapProps {
  width: number;
  height: number;
  top?: number;
  left?: number;
  children?: ReactNode;
  title?: string;
  mapTitle?: boolean;
  layerId: string;
  details?: boolean;
  timeseries?: boolean;
  timeslider?: boolean;
}
export interface WithParentSizeProps {
  children?: ReactNode;
  withZoom?: boolean;
  panels?: ReactNode;
}

export default DroughtMap;
