import { useQuery } from "react-query";
import { GetTimeseriesRequest } from "../types/timeseries";
import { getIpcScoreFeatureAndGroup } from "../services/ipcScore";
import useQueryParameters from "./useQueryParameters";
import { getDecadeNumber, getMonthNumber } from "./useLayers";

export type UseTimeseries = GetTimeseriesRequest;

const useIPCScore = () => {
  const { queryParameters: { ipcScore, queryId } } = useQueryParameters();

  try {
    const ipcScoreResponse = useQuery(
      ["ipcScore", queryId],
      async () => await getIpcScoreFeatureAndGroup({
        queryId,
      }),
      {
        retry: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        enabled: ipcScore
      }
    );

    return ipcScoreResponse?.data;
  } catch (error) {
    console.error("Error in useIpcScore:", error);
    throw error;
  }
};

export default useIPCScore;
