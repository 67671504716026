import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import faqMap from "../../assets/faq/faq-map.png";
import formula from "../../assets/faq/formula.png";

const FAQComponent = () => {
  const faqData = [
    {
      question:
        "What is the main purpose of the drought risk financing dashboard and who can benefit from its insights?",
      answer:(
        <Typography>The main purpose of the online dashboard is to offer a user-friendly way to access satellite data to understand drought risk as well as to develop and validate parametric drought risk financing indices. It is intended to aid understanding and reduction of basis risk by making the development process more transparent. The dashboard provides access to pre-processed satellite-derived climate and drought loss data, the opportunity to tweak insurance parameters, and visualizations of simulated historical payouts.</Typography>
      )
    },
    {
      question: "What is basis risk? ",
      answer: (
        <Typography>Basis risk refers to the discrepancy between actual losses and those covered or predicted by a financial instrument. It can have several different sources, such as the data driving the model, the model itself, uncovered hazards (e.g. floods or pests), or issues related to the communication of the financial instrument.</Typography>
      )
    },
    {
      question: "Which regions does the dashboard cover?",
      answer:(<Typography>The dashboard currently covers Ethiopia, Kenya, and Somalia. Other countries will follow in subsequent phases. All climate datasets used in the dashboard are openly available globally.</Typography>)
    },
    {
      question:
        "How were the climate datasets selected and which periods to they cover?  ",
      answer:
      (
        <Box>
          <Typography>The three climate datasets (satellite-derived precipitation, root-zone soil moisture, vegetation health) were mainly chosen based on five factors: 1) complementarity 2) data quality and available validation studies 3) temporal coverage (at least 15 years) 4) latency, and 5) high likelihood of long-term data delivery. More detailed information can be found in the dashboard’s full documentation. </Typography><br />
          <Typography>Temporal coverage:</Typography><br />
          <Typography>CHIRPS Rainfall: 1981-present (merged CHIRPS and CHIRPS prelim) </Typography>
          <Typography>Copernicus Soil Water Index (SWI): 2007-present </Typography>
          <Typography>eVIIRS Vegetation Greenness (Normalized Difference Vegetation Index; NDVI): 2002-present (merged with MODIS NDVI) </Typography>
        </Box>
      )
    },
    {
      question:
        "How is the CHIRPS prelim product different from the standard CHIRPS dataset? ",
      answer:
      (
        <Box>
          <Typography>
            The final CHIRPS rainfall product has a latency of 1.5 months – i.e. data is not available until 1.5 months after the satellite observation - and includes data from a higher number of weather stations than the prelim (= preliminary) product. Both products were merged, resulting in a longer temporal reference (CHIRPS prelim is only available since 2015). However, working with a merged product means that the last 1.5 months of rainfall data might be subject to quality issues in regions where the input from weather stations has a strong influence to correct over- or underestimations of the satellite sensor. You can check the difference between the two datasets for every month here: <a href="https://wiki.chc.ucsb.edu/CHIRPS_Reality_Checks" target="_blank">https://wiki.chc.ucsb.edu/CHIRPS_Reality_Checks</a>
          </Typography>
        </Box>
      )
    },
    {
      question:
        "Are there known regions in which any of the climate datasets show high levels of uncertainty?  ",
      answer:
      (
        <Box>
          <Typography>
            The estimation of climate and vegetation conditions is affected by various factors, such as complex topography, tropical forests, or dry (arid) regions. In East Africa, particularly the latter is problematic. Particularly in areas categorized as bare land/sparse vegetation (map below) users need to be cautious, because errors in climate data propagate through all development steps, affecting the final financial product.  Currently, satellite data used in the dashboard in regions where the uncertainty is expected to be higher are not masked out (excluded) to allow a complete view of the data – however this increases the need for caution when interpreting results.
          </Typography><br />
          <Box textAlign="center" bgcolor="white">
            <img width="100%" alt="faqMap" src={faqMap} />
          </Box>
        </Box>
      )
    },
    {
      question:
        "What does the concept of ‘convergence of evidence’ mean for drought risk financing?   ",
      answer: (
        <Typography>The dashboard relies on multiple independent climate observations to corroborate the severity and extent of drought conditions. This ‘convergence of evidence’ approach reduces the reliance on one single climate dataset, increasing the overall reliability of the dashboard. In the current configuration, the dashboard uses three interdependent climate variables: satellite-derived rainfall, soil moisture, and vegetation greenness as a proxy for vegetation health.</Typography>
      )
    },
    {
      question: "What is a climate anomaly and how is it calculated? ",
      answer:
      (
        <Box>
          <Typography>
            There are <a href="https://www.droughtmanagement.info/literature/GWP_Handbook_of_Drought_Indicators_and_Indices_2016.pdf" target="_blank">more than 50 different drought indices of indicators</a>, ranging from very simple to complex formulas. We rely on 10-daily standardized anomalies (Z-scores) for all climate and vegetation variables.
          </Typography><br/>
          <Box textAlign="center">
            <img height="150px" alt="formula" src={formula} />
          </Box>
        </Box>
      )
    },
    {
      question:
        "At what geographic scale can climate data be aggregated, and indices developed?",
      answer: (
        <Typography>
          Indices can be currently calculated at Admin 1 and Admin 2 level.
        </Typography>
      ),
    },
    {
      question:
        "Which datasets are used to represent historical drought impacts/losses? ",
      answer: (
        <Typography>
          We use two datasets to represent drought impacts: drought statistics from the <a href="http://www.emdate.be/" target="_blank">International Disasters Database</a> and
          the <a href="https://fews.net/about/integrated-phase-classification" target="_blank">Integrated Food Security Phase Classification</a> (IPC) scale for Acute Food Insecurity.
          IPC scores are generated at the level of FEWS NET livelihood zones
          (higher resolution than Admin 2), but we use the maximum, annual IPC
          value for the chosen Admin level.
        </Typography>
      ),
    },
    {
      question:
        "How are trigger and exit values defined and can I change them? ",
      answer: (
        <Typography>
          Initial trigger and exit values are auto-selected based on standard
          return periods (1 in 5, 1 in 20 years), but can always be changed.
        </Typography>
      ),
    },
    {
      question:
        "How are drought conditions related to socioeconomic impacts, such as crop failure or food insecurity? ",
      answer: (
        <Box>
          <Typography>
            Agricultural droughts can cause crop failure, often leading to a 'hunger gap' – a delay between the drought onset and resultant food insecurity, as dwindling food supplies and rising prices gradually impact communities. This means that there might be many months between climate variables indicating drought conditions and increasing food insecurity.
          </Typography><br />
          <Typography>
            In addition, drought shocks can disrupt economic development and jeopardize all kinds of development gains. Exposure to drought in early childhood leads to lower adult wealth, reduced education, and shorter adult stature in women raised in rural households, indicating the long-term socioeconomic impacts of drought-related crop loss (see for instance Hyland and Russ, 2019).
          </Typography>
        </Box>
      ),
    },
    {
      question:
        "How can I validate historical insurance payouts with my own data? ",
      answer: (
        <Box>
          <Typography>
            We strongly encourage all kinds of validation exercises,
            particularly if you have trusted loss data available for the target
            regions. You can download the data via csv (results only)
          </Typography>
        </Box>
      ),
    },
    {
      question: "Can I select more than one critical window? ",
      answer: (
        <Box>
          <Typography>
            No, at the moment the dashboard is restricted to one insurance
            window.
          </Typography>
        </Box>
      ),
    },
    {
      question:
        "Can I compare historical payouts based on different climate variables and for different geographic units? ",
      answer: (
        <Box>
          <Typography>Yes.</Typography>
        </Box>
      ),
    },
    {
      question: "Who can I contact if I have questions? ",
      answer: (
        <Box>
          <Typography>
            Please contact John Luke Plevin (<a href={`mailto:jplevin@worldbank.org`}>jplevin@worldbank.org</a>) or Markus
            Enenkel (<a href={`mailto:menenkel@worldbank.org`}>menenkel@worldbank.org</a>).
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      {faqData.map((faq, index) => (
        <Accordion key={index} sx={{ width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`faq-${index}-content`}
            id={`faq-${index}-header`}
          >
            <Typography variant="h6" fontSize="16px">
              {faq.question}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {faq.answer}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQComponent;
