import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { HeroProps } from "./types";
import { Typography } from "@mui/material";

const Hero = ({ headline, description, subtitle, children }: HeroProps) => {
  return (
    <Box
      sx={{
        paddingY: "3rem",
        width: "660px",
        height: "auto",
      }}
    >
      <Box>
        <Typography
          variant="h1"
          fontSize="34px"
          lineHeight="24px"
          textAlign="left"
        >
          {headline}
        </Typography>
        {subtitle && <Typography
          variant="h1"
          fontSize="24px"
          lineHeight="24px"
          textAlign="left"
          paddingTop="36px"
        >
          {subtitle}
        </Typography>}
        <Typography paddingTop="30px">{description}</Typography>
        {children}
      </Box>
    </Box>
  );
};

export default Hero;
