import { useMutation } from "react-query";
import { GetFeatureInfoRequest } from "../types/featureInfo";
import { getFeatureInfo as getFeatureInfoService } from "../services/featureInfo";
import { QueryParams } from "./useQueryParameters";

const useFeatureInfo = (queryParameters: QueryParams) => {
  const getFeatureInfo = useMutation(
    ({ layerId, bbox, width, height, x, y, date }: GetFeatureInfoRequest) =>
      getFeatureInfoService({
        layerId,
        bbox,
        width,
        height,
        x,
        y,
        date,
        queryParameters,
      }),
    {}
  );

  return getFeatureInfo;
};

export default useFeatureInfo;
