import { 
  Box,
  FormControlLabel, 
  IconButton, 
  Switch, 
  Typography 
} from "@mui/material";

import { LayerProps } from "./types";
import useQueryParameters from "../../../hooks/useQueryParameters";
import { Info } from "@mui/icons-material";

const Layer = ({
  id,
  parentId,
  displayName,
  isDisabled,
  isVisible,
  time,
  timeseriesGroupId,
  layers = [],
  dataunit,
  openDialog
}: LayerProps) => {
  const { queryParameters, toggleLayers } =
    useQueryParameters();

  const { layers: layersFromQuery = [] } = queryParameters;

  const isChecked = !!layersFromQuery.find((lay) => lay.id === id);

  const isDroughtRiskStep = window.location.pathname.includes('drought-risk')
  const withNestedLayers = layers !== null && !!layers.length;

  const layersToSet = [
    ...(withNestedLayers
      ? layers.map((layer) => ({
          id: layer.id,
          timeseriesGroupId: layer.timeseriesGroupId,
          isVisible: true,
          dataunit: layer.dataunit,
          displayName: layer.displayName,
          ...(layer.time?.default && {
            date: new Date(layer.time?.default),
            time: layer.time,
          }),
        }))
        : []),
        {
          id,
          ...(parentId && {
            parentId,
          }),
          isVisible: true,
          dataunit,
          displayName,
          ...(time?.default && {
            date: new Date(time?.default),
          }),
          time,
          timeseriesGroupId,
    },
  ];

  const handleChange = () => {
    toggleLayers(layersToSet, isDroughtRiskStep);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Switch
            checked={isChecked}
            onChange={handleChange}
            disabled={isDisabled}
            size="small"
          />
        }
        label={
          <Box display="flex" gap="5px" height="30px" alignItems="center">
            <Typography>{displayName}</Typography>
            <IconButton size="small" onClick={(e) => openDialog(e, displayName)}>
              <Info fontSize="small"/>
            </IconButton>
          </Box>
        }
        labelPlacement="start"
        componentsProps={{
          typography: {
            fontSize: '16px'
          },
        }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: 0,
          padding: 0,
          width: "100%",
        }}
      />
      {isChecked && (
        <Box>
          {layers?.map((layer) => (
            <Layer key={layer.id} {...layer} parentId={id} openDialog={openDialog}/>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default Layer;
