import { useQuery } from "react-query";

import { GetTimeseriesRequest } from "../types/timeseries";
import { getFinancialTimeseriesByFeature } from "../services/financialTimeseries";
import useQueryParameters from "./useQueryParameters";
import { getDecadeNumber, getMonthNumber } from "./useLayers";

export type UseTimeseries = GetTimeseriesRequest;

const useTimeseriesFinancial = () => {
  const { queryParameters: { 
    geographicalAreas,
    financialLayers,
    insuranceWindowMonth,
    insuranceWindowDekad,
    insuranceWindowMonthTo,
    insuranceWindowDekadTo,
    exitLevel,
    trigerLevel,
    maxPayout,
    minPayout,
    deductable,
    sum_insured,
   }} = useQueryParameters();

  const timeseries = useQuery(
    [
      "timeseries",
      geographicalAreas,
      financialLayers,
      insuranceWindowMonth,
      insuranceWindowDekad,
      insuranceWindowMonthTo,
      insuranceWindowDekadTo,
      exitLevel,
      trigerLevel,
      maxPayout,
      minPayout,
      deductable,
      sum_insured,
    ],
    () =>
      getFinancialTimeseriesByFeature({
        geographicalAreas,
        financialLayers,
        monthFrom: getMonthNumber(insuranceWindowMonth),
        monthTo: getMonthNumber(insuranceWindowMonthTo),
        dekadFrom: getDecadeNumber(insuranceWindowDekad),
        dekadTo: getDecadeNumber(insuranceWindowDekadTo),
        exitLevel,
        trigerLevel,
        maxPayout: Number(maxPayout),
        minPayout: Number(minPayout),
        deductable: Number(deductable),
        sum_insured: Number(sum_insured),
      }),
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: Boolean(geographicalAreas.length > 0 && financialLayers.length > 0 && insuranceWindowMonth && insuranceWindowDekad &&
      insuranceWindowMonthTo && insuranceWindowDekadTo && exitLevel && trigerLevel && maxPayout && minPayout && deductable &&
      sum_insured),
    }
  );

  return timeseries;
};

export default useTimeseriesFinancial;
