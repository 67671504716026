import { getAxiosError } from "../helpers/src";

import { GetTimeseriesRequest } from "../types/timeseries";

import extractQueryId from "../helpers/extractQueryId";
import axios from "axios";
import { climateDataStore } from "../helpers/src/ClimateDataStore";
import { sortArrayOfObject } from "../helpers/array";
import { getBackendURL } from "./backendURL";

export const getTimeseriesByFeature = async ({
  queryId,
  country,
  isClimateData,
  groupId,
  monthFrom,
  monthTo,
  dekadFrom,
  dekadTo,
  droughtEvents,
  ipcScore,
}: GetTimeseriesRequest) => {
  const getURL = await getBackendURL();
  const baseURL = getURL.backendURL;

  try {
    const { featureId } = extractQueryId(queryId ?? "");

    let queryParametersFeature = `feature_id=${featureId}`;

    if (isClimateData) {
      queryParametersFeature += `&group_id=${groupId}`;
    } else {
      climateDataStore.forEach((indices) => {
        queryParametersFeature += `&group_id=${indices.timeseriesGroupId}`;
      });
    }

    // todo refactor
    const climateTimeseriesEndpoint = `${baseURL}/timeseries/timeseries_by_featureid_from_group?${queryParametersFeature}`;
    const anomalyTimeseriesEndpoint = `${baseURL}/timeseries/get_dekad_timeseries_anomaly?${queryParametersFeature}`;
    const payload = {
      startmonth: monthFrom,
      endmonth: monthTo,
      startdekat: dekadFrom,
      enddekat: dekadTo,
    };

    const response = isClimateData
      ? await axios.get(climateTimeseriesEndpoint)
      : await axios.post(anomalyTimeseriesEndpoint, payload);

    return sortArrayOfObject(response.data, "groupid");
  } catch (error) {
    const errors = getAxiosError(error);
    throw new Error(JSON.stringify(errors));
  }
};
