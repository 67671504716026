import { useEffect, useState } from "react";
import { PickingInfo } from "@deck.gl/core/typed";
import { _WMSLayer as WMSLayer } from "@deck.gl/geo-layers/typed";
import { MjolnirEvent } from "mjolnir.js";

import useQueryParameters from "./useQueryParameters";
import useFeatureInfo from "./useFeatureInfo";
import { get } from "lodash";
import { useQuery } from "react-query";
import { startAndEndDate } from "../services/startAndEndDate";
import { getBackendURL } from "../services/backendURL";

interface UseLayers {
  width: number;
  height: number;
}

const useLayers = ({ width, height }: UseLayers) => {
  const { queryParameters, setQueryParameters } = useQueryParameters();
  const [isLoading, setIsLoading] = useState(false);

  const [geoserverURL, setGeoserverURL] = useState("");

  useEffect(() => {
    const fetchURLs = async () => {
      const urls = await getBackendURL();
      setGeoserverURL(urls.geoserverURL);
    };

    fetchURLs();
  }, []);

  const { layers: layersFromQuery, timeseries } = queryParameters;
  const getFeatureInfo = useFeatureInfo(queryParameters);

  const handleLayerClick = (pickingInfo: PickingInfo, _event: MjolnirEvent) => {
    if (timeseries) setQueryParameters({ timeseries: "hide", geounit: "" });
    const { x, y, viewport: viewportFromInfo, coordinate = [] } = pickingInfo;

    if (x <= 0 && y <= 0) return;

    const [longitude, latitude] = coordinate;

    if (!longitude && !latitude) return;

    const bbox = viewportFromInfo?.getBounds();

    const newWidth = viewportFromInfo?.width || width;
    const newHeight = viewportFromInfo?.height || height;

    if (!bbox) return;
    const id = layersFromQuery[0].id;

    getFeatureInfo.mutate(
      {
        layerId: id as string,
        x: Math.round(x),
        y: Math.round(y),
        bbox,
        width: newWidth,
        height: newHeight,
        queryParameters,
      },
      {
        onSuccess: ({ features = [] }) => {
          const queryId = get(features, "0.properties.query_id", undefined);
          const nameFeature = get(
            features,
            "0.properties.feature_name",
            undefined
          );

          setQueryParameters({
            queryId: queryId ?? "",
            geographicalUnit: nameFeature,
            timeseries: "show",
          });
        },
      }
    );
  };

  const periodStart = getMonthNumber(
    queryParameters.insuranceWindowMonth.toString()
  );
  const periodEnd = getMonthNumber(
    queryParameters.insuranceWindowMonthTo.toString()
  );

  const monthsBetween = getMonthsBetween(periodStart, periodEnd);
  const monthsBetweenParam = monthsBetween.length > 0 ? monthsBetween : null;
  const allMonths = getAllMonths(periodStart, periodEnd);
  const decadeFrom = getDecadeNumber(queryParameters.insuranceWindowDekad);
  const decadeTo = getDecadeNumber(queryParameters.insuranceWindowDekadTo);

  const decadeDayFrom = getFirstDayOfDecade(decadeFrom);
  const decadeDayTo = getFirstDayOfDecade(decadeTo);
  const groupId =
    layersFromQuery.length >= 1 && layersFromQuery[0]
      ? layersFromQuery[0].timeseriesGroupId
      : "c354e875-caaa-4b1e-b046-bf6ab3efa22d";

  const response = useQuery(
    [
      "startAndEnd",
      queryParameters.insuranceWindowMonth,
      queryParameters.insuranceWindowDekad,
      queryParameters.insuranceWindowMonthTo,
      queryParameters.insuranceWindowDekadTo,
      groupId,
    ],
    () =>
      startAndEndDate(periodStart, decadeFrom, periodEnd, decadeTo, groupId),
    {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );

  const datesAccrosYear = isAcrossYear(
    queryParameters.insuranceWindowMonth,
    queryParameters.insuranceWindowMonthTo
  );

  const periodParameter = datesAccrosYear ? "11\%5C,12" : allMonths;

  const startDateCalculated = response["data"]?.startdate;
  const endDateCalculated = response["data"]?.enddate;
  const layers = layersFromQuery.reverse().map((layer, index) => {
    const template = {
      TRANSPARENT: true,
      VERSION: "1.1.0",
      REQUEST: "GetMap",
      FORMAT: "image/png",
      CRS: "EPSG:3857",
      SERVICE: "WMS",
      HEIGHT: "768",
      WIDTH: "717",
      LAYERS: "{layers}",
      BBOX: "{east},{north},{west},{south}",
      TIME: layer.date?.toString() || (layer.time?.default ? new Date(layer.time?.default).toISOString() : new Date().toISOString()),
      VIEWPARAMS: `country:${queryParameters.country};period:${periodParameter};period_end:${periodEnd};months_between:${monthsBetweenParam};period_filter:${allMonths};period_start:${periodStart};day_from:${decadeDayFrom};day_to:${decadeDayTo};startDateCalculated:${startDateCalculated};endDateCalculated:${endDateCalculated}`,
    };

    const finalQueryParameters = Object.entries(template)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const doNothing = () => {};

    return new WMSLayer({
      id: `${layer.id}`,
      data: `${geoserverURL}/geoserver/NextGenDrought/wms?${finalQueryParameters}`,
      serviceType: "template",
      layers: [layer.id + `_${queryParameters.agregation}`],
      pickable: true,
      onClick: handleLayerClick,
      // onHover: handleLayerHover,
      beforeId: "aerialway",
      onMetadataLoadError: doNothing,
      onImageLoad: () => setIsLoading(false),
      onImageLoadStart: () => setIsLoading(true),
    });
  });

  return { layers, isLoading: isLoading || getFeatureInfo.isLoading };
};

export function getMonthNumber(monthName: string): number {
  const months: { [key: string]: number } = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  const monthNumber = months[monthName];
  return monthNumber !== undefined ? monthNumber : -1;
}

export function getDecadeNumber(decadeName: string): number {
  const decades: { [key: string]: number } = {
    one: 1,
    two: 2,
    three: 3,
  };

  const decadeNumber = decades[decadeName];
  return decadeNumber !== undefined ? decadeNumber : -1;
}
export function getMonthsBetween(fromMonth: number, toMonth: number): string {
  if (!fromMonth || !toMonth) return "";
  let months = [];
  let currentMonth = fromMonth;

  do {
    currentMonth = (currentMonth % 12) + 1;
    if (currentMonth != toMonth) months.push(currentMonth);
  } while (currentMonth !== toMonth);

  return months.join("\%5C,");
}

export function getAllMonths(fromMonth: number, toMonth: number): string {
  let months = [];
  let currentMonth = fromMonth;

  do {
    months.push(currentMonth);
    if (currentMonth === toMonth) {
      break;
    }
    currentMonth = (currentMonth % 12) + 1;
  } while (true);

  return months.join("\%5C,");
}

export function isAcrossYear(startMonth: string, endMonth: string): boolean {
  const monthToNumber: { [key: string]: number } = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  const startMonthNumber = monthToNumber[startMonth];
  const endMonthNumber = monthToNumber[endMonth];

  if (startMonthNumber === undefined || endMonthNumber === undefined) {
    return false;
  }

  if (startMonthNumber > endMonthNumber) {
    return true;
  }

  return false;
}

export function getFirstDayOfDecade(decadeNumber: number): number {
  const firstDay = (decadeNumber - 1) * 10 + 1;

  return firstDay;
}

const firstDayOfDecade1 = getFirstDayOfDecade(1);
const firstDayOfDecade2 = getFirstDayOfDecade(2);
const firstDayOfDecade3 = getFirstDayOfDecade(3);

export default useLayers;
