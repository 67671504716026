import {
  Box,
  ButtonBase,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import useQueryParameters, { QueryLayer } from "../../hooks/useQueryParameters";
import useLegends from "../../hooks/useLegends";
import LayerDate from "../LayerDate/LayerDate";
import Point from "./Point/Point";
import Raster from "./Raster/Raster";
import Polygon from "./Polygon/Polygon";
import Line from "./Line/Line";
import DraggableList from "../DraggableList/DraggableList";

const LegendsGroup = ({
  id: layerId,
  date,
  dateMode,
}: Pick<QueryLayer, "id" | "date" | "dateMode">) => {
  const { queryParameters, setQueryParameters } = useQueryParameters();
  const { data: legendsData } = useLegends({
    layerId,
    aggregationLevel: queryParameters.agregation,
  });
  const { Legend: legends = [] } = legendsData || {};

  const isChecked = dateMode === "slider";

  return (
    <>
      {legends.map(({ layerName, rules = [] }) => (
        <Stack
          key={layerName}
          spacing="0.25rem"
          sx={{
            backgroundColor: "white",
            width: "80%",
            padding: "1rem",
          }}
        >
          {rules.map(({ symbolizers, title: ruleTitle }, ruleIndex) => (
            <Box key={ruleIndex}>
              {ruleTitle && symbolizers.map(
                (
                  {
                    Point: point,
                    Raster: raster,
                    Polygon: polygon,
                    Line: line,
                  },
                  symbolizerIndex
                ) => {
                  if (point) {
                    return (
                      <Point
                        key={symbolizerIndex}
                        {...point}
                        ruleTitle={ruleTitle}
                      />
                    );
                  } else if (raster) {
                    return <Raster key={symbolizerIndex} {...raster} />;
                  } else if (polygon) {
                    return (
                      <Polygon
                        key={symbolizerIndex}
                        {...polygon}
                        ruleTitle={ruleTitle}
                      />
                    );
                  } else if (line) {
                    return (
                      <Line
                        key={symbolizerIndex}
                        {...line}
                        ruleTitle={ruleTitle}
                      />
                    );
                  } else {
                    return null;
                  }
                }
              )}
            </Box>
          ))}
        </Stack>
      ))}
    </>
  );
};

const Legends = ({layerId}: {layerId: string}) => {
  const { queryParameters, reorderLayers } = useQueryParameters();
  const { layers } = queryParameters;
  
  return (
    <Stack>
      <DraggableList onDrag={reorderLayers}>
          <LegendsGroup id={layerId} />
      </DraggableList>
    </Stack>
  );
};

export default Legends;
