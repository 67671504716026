import { GetTimeseriesRequest } from "../types/timeseries";
import { useQuery } from "react-query";
import { timeseriesStartEnd } from "../services/timeseriesStartEnd";

export type UseTimeseries = GetTimeseriesRequest;

const useTimeseriesDates = (
  monthFrom: number,
  dekadFrom: number,
  monthTo: number,
  dekadTo: number,
  groupId?: string
) => {
  return useQuery(
    ["timeseriesYears", monthFrom, dekadFrom, monthTo, dekadTo, groupId],
    () => timeseriesStartEnd(monthFrom, dekadFrom, monthTo, dekadTo, groupId),
    {
      retry: false,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
    }
  );
};


export default useTimeseriesDates;
