import { Box, Typography } from "@mui/material";
import Hero from "../../Components/Hero";
import Layout from "../../Components/Layout";

const About = () => {
  return (
    <Layout showFilterSidebar={false} withFooter={false}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Hero
          headline="About the NGDI Dashboard"
          description="
          The dashboard was developed under the World Bank Disaster Risk Finance and Insurance Program, funded by the Global Shield Financing Facility (GSFF) and the Global Index Insurance Facility (GIIF). It represents a demonstration and test of a new concept to streamline index design and validation. The team intends to develop the dashboard further (new countries, functionalities, and use cases) based on user experience and feedback. 
          The Global Shield Financing Facility (GSFF) is a Multi-Donor Trust Fund (MDTF) hosted by the World Bank and financed by the Governments of Canada, Germany, Japan and the United Kingdom. The program aligns with the principles of the Global Shield against Climate Risk, a G7 initiative intended to promote a coherent and sustained global system of financial protection of poor and vulnerable people and countries against climate risks. Through its grants, GSFF aims to support poor and vulnerable countries and people with increased access to financial protection against climate shocks, disasters, and crises, through technical advisory services and integrated financial packages to address protection gaps, as well as building strategic partnerships."
        >
          <Box>
            <Typography paddingTop="30px">
              The Global Index Insurance Facility (GIIF) is a specific program of
              the World Bank Group that facilitates access to financing for
              small-scale farmers, micro-entrepreneurs, and microfinance
              institutions by providing solutions for catastrophic risk transfer
              and index-based insurance in developing countries. Funded by the
              European Union, the governments of Germany, Japan, and the
              Netherlands, the GIIF has facilitated approximately 13 million
              contracts, covering nearly 65 million people worldwide, and has
              facilitated at least 2 billion dollars in financing, primarily for
              farmers in sub-Saharan Africa, Asia, Latin America, and the
              Caribbean. The dashboard builds on work carried out previously in
              partnership with the European Space Agency’s Center for Earth
              Observation (ESA/ESRIN) under the Next Generation Drought Index
              (NGDI) project. The NGDI project represents a suite of activities
              carried out in collaboration with various expert institutions,
              namely: the International Research Institute for Climate and Society
              (IRI), Vienna University of Technology, the International Water
              Management Institute, and AIR Worldwide. Close collaboration with
              national stakeholders and UN organizations and advisory partners (UN
              WFP, START Network, Global Index Insurance Facility) led to various
              important lessons learned that shaped the development of this
              project.
            </Typography>
          </Box>
        </Hero>
      </Box>
    </Layout>
  );
};

export default About;
